import Header from "../components/Header";
import clientLogo from "../assets/client-logo.png";
import Input from "../components/Input";
import Keyboard from "../components/Keyboard";
import { useEffect, useState } from "react";
import IconButton from "../components/IconButton";
import ShowToast from "../components/ShowToast";
import { partnerLogin } from "../services/api.service";
import { useDispatch } from "react-redux";
import { setPartnerToken } from "../store/rootReducer";
// import { useHistory } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const DoctorLogin = () => {
  const [formData, setFormData] = useState({
    username: "",
    password: "",
  });
  const [selectedInput, setSelectedInput] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    // const recievedAction = props?.location?.state?.action;
    // switch (recievedAction) {
    //   case "reload":
    //     window.location.reload();
    // }
    if (localStorage.getItem("partnerToken")) {
      if (localStorage.getItem("kioskId")) {
        navigate("/home");
      } else {
        localStorage.clear();
        navigate("/");
      }
    }
  }, []);
  const onKeyboardInputChange = (key, value) => {
    setFormData({
      ...formData,
      [key]: value,
    });
  };
  const isFormValid = () => {
    let isValid = true;
    const { username, password } = formData;
    if (!username || !password) {
      isValid = false;
      return;
    }
    // isValid = password.match("^[6-9]{1}[0-9]{9}$");
    return isValid;
  };
  const handleClick = async () => {
    console.log("Shree Ganesh");
    console.log(formData);
    const { username, password } = formData;
    const isValid = isFormValid();
    if (!isValid) {
      ShowToast.error("Invalid Input. Please provide correct values");
    } else {
      setIsLoading(true);
      try {
        const result = await partnerLogin(username, password);
        if (result?.data?.token) {
          ShowToast.success("Login Success!");
          let token = result.data.token;
          dispatch(setPartnerToken(token));
          navigate("/kiosk-selection", { state: result.data });
        } else {
          console.error("Token not found in the response");
          ShowToast.error("Something went wrong while login");
        }
      } catch (error) {
        ShowToast.error("Something went wrong while login");
      } finally {
        setIsLoading(false);
      }
    }
  };
  return (
    <div
      className="flex flex-col w-full justify-start text-center "
      style={{
        height: "100vh",
      }}
    >
      {/* Shree Ganeshji */}
      <Header
        clientLogo={clientLogo}
        // patientDetails={{ name: "Rajat Kabade", password: "+91-9812345678" }}
      />
      <br />
      <div className="text-6xl font-bold text-[#344277] flex flex-row justify-center">
        Hi There! &nbsp;
        <div className="text-6xl animate-[wiggle_1s_ease-in-out_infinite]">
          👋
        </div>
      </div>
      <br />
      <div className="tracking-wide text-[#7C87AF]">
        You are 3 steps away from magical AI dental scan
      </div>
      <br />
      <Input
        // ref={inputReference}
        onFocus={() => {
          setSelectedInput("username");
        }}
        placeholder={"Username"}
        label={"Username"}
        value={formData?.username}
        onChange={(event) => {
          const { value } = event?.target;
          onKeyboardInputChange("username", value);
        }}
      />
      <Input
        onFocus={() => {
          setSelectedInput("password");
        }}
        placeholder={"Password"}
        label={"Password"}
        value={formData?.password}
        onChange={(event) => {
          const { value } = event?.target;
          onKeyboardInputChange("password", value);
        }}
      />
      <br />
      <div>
        <IconButton
          buttonText={"Submit"}
          isLoading={isLoading}
          onClick={handleClick}
        />
      </div>
      <Keyboard
        onChange={(value) => onKeyboardInputChange(selectedInput, value)}
        inputReference={selectedInput === "password" ? "numbers" : "normal"}
      />
    </div>
  );
};

export default DoctorLogin;
