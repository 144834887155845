export const diseasesMap = {
  "pit and fissure caries": {
    color: "#0000FF",
    gujarati: {
      name: "ખાડો અને નકામું કામચલાઉ",
      description: "દાંતના સડોની શરૂઆત",
      treatment: "રંગ ભરવા જેવા દાંત",
      price: "₹ 200 - 350",
    },
    kannada: {
      name: "ಪಿಟ್ ಮತ್ತು ಬಿರುಕು ಕ್ಷಯ",
      description: "ಹಲ್ಲಿನ ಕ್ಷಯದ ಪ್ರಾರಂಭ",
      treatment: "ಬಣ್ಣದ ತುಂಬುವಿಕೆಯಂತಹ ಹಲ್ಲು",
      price: "₹ 200 - 350",
    },
    english: {
      name: "Pit and fissure caries",
      description: "Start of a tooth decay",
      treatment: "Tooth like colored filling",
      price: "₹ 200 - 350",
    },
    marathi: {
      name: "थोडा कीड",
      description: "दात किडण्याची प्रारंभिक सुरुवात",
      treatment: "दाता-समान रंग असणारी फीलिन्ग",
      price: "₹ 200 - 350",
    },
    hindi: {
      name: "शुरुआती कीड़",
      description: "कीड़ की शुरुआत",
      treatment: "दाँत के रंग का फिलिंग",
      price: "₹ 200 - 350",
    },
    tamil: {
      name: "குழி மற்றும் பிளவு பூச்சிகள்",
      description: "பல் சிதைவின் ஆரம்பம்",
      treatment: "வண்ண நிரப்புதல் போன்ற பல்",
      price: "₹ 200 - 350",
    },
    telugu: {
      name: "పిట్ మరియు ఫిషర్ క్షయం",
      description: "దంత క్షయం ప్రారంభం",
      treatment: "రంగు పూరకం వంటి దంతాలు",
      price: "₹ 200 - 350",
    },
    ar: {
      name: "تسوس الحفرة والشقوق",
      description: "بداية تسوس الأسنان",
      treatment: "الأسنان مثل الحشوة الملونة",
      price: "₹ 200 - 350",
    },
  },
  "deep caries": {
    color: "#FF8300",
    gujarati: {
      name: "Deepંડા અસ્થિભંગ",
      description: "એક er ંડા પોલાણ કે જે દુ painful ખદાયક હોઈ શકે",
      treatment: "XRAY શોધવા મુજબ ડીપ ફિલિંગ/ રુટ કેનાલ ટ્રીટમેન્ટ",
      price: "₹ 500 - 1140",
    },
    kannada: {
      name: "ಆಳವಾದ ಕ್ಷಯ",
      description: "ನೋವಿನಿಂದ ಕೂಡಿದ ಆಳವಾದ ಕುಳಿ",
      treatment: "ಎಕ್ಸರೇ ಶೋಧನೆಯ ಪ್ರಕಾರ ಡೀಪ್ ಫಿಲ್ಲಿಂಗ್/ ರೂಟ್ ಕೆನಾಲ್ ಚಿಕಿತ್ಸೆ",
      price: "₹ 500 - 1140",
    },
    english: {
      name: "Deep caries",
      description: "A deeper cavity that might be painful",
      treatment: "Deep filling/ Root canal treatment according to Xray finding",
      price: "₹ 500 - 1140",
    },
    marathi: {
      name: "खोल कीड",
      description: "खोल कीड जी वेदनादायक असू शकते",
      treatment:
        "रूट कॅनॉल ट्रीटमेंट/फीलिन्ग करणे हे xray तपासणीवर अवलंबून आहे",
      price: "₹ 500 - 1140",
    },
    hindi: {
      name: "गहरा कीड़",
      description: "एक गहरा कीड़ा जो दर्दनाक हो सकता है",
      treatment: "ग़हरा फिलिंग/रूट कैनाल उपचार एक्सरे फाइंडिंग के अनुसार",
      price: "₹ 500 - 1140",
    },
    tamil: {
      name: "ஆழமான பூச்சிகள்",
      description: "வலிமிகுந்த ஆழமான குழி",
      treatment:
        "எக்ஸ்ரே கண்டுபிடிப்பின் படி ஆழமான நிரப்புதல் / ரூட் கால்வாய் சிகிச்சை",
      price: "₹ 500 - 1140",
    },
    telugu: {
      name: "లోతైన క్షయం",
      description: "బాధాకరంగా ఉండే లోతైన కుహరం",
      treatment: "ఎక్స్రే నిర్ధారణ ప్రకారం డీప్ ఫిల్లింగ్/ రూట్ కెనాల్ చికిత్స",
      price: "₹ 500 - 1140",
    },
    ar: {
      name: "تسوس عميق",
      description: "تجويف أعمق قد يكون مؤلمًا",
      treatment:
        "علاج قناة التعبئة العميقة/ الجذر وفقًا لاكتشاف الأشعة السينية",
      price: "₹ 500 - 1140",
    },
  },
  stains: {
    color: "#FF0000",
    gujarati: {
      name: "ડાઘ",
      description: "ભૂરાશથી કાળા રંગનો વિકૃતિકરણ",
      treatment: "દાંત સફાઈ અને પોલિશિંગ",
      price: "₹ 150",
    },
    kannada: {
      name: "ಕಲೆಗಳು",
      description: "ಕಂದು ಬಣ್ಣದಿಂದ ಕಪ್ಪು ಬಣ್ಣದ ಬಣ್ಣ",
      treatment: "ಹಲ್ಲುಗಳನ್ನು ಸ್ವಚ್ಛಗೊಳಿಸುವುದು ಮತ್ತು ಹೊಳಪು ಮಾಡುವುದು",
      price: "₹ 150",
    },
    english: {
      name: "Stains",
      description: "Brownish to blackish discoloration",
      treatment: "Teeth cleaning and polishing",
      price: "₹ 150",
    },
    marathi: {
      name: "दातांवर डाग",
      description: "तपकिरी ते काळ्या रंगाचा दाग",
      treatment: "दात स्वच्छ करणे",
      price: "₹ 150",
    },
    hindi: {
      name: "दांतों पर दाग",
      description: "भूरे से काले रंग का मलिनकिरण",
      treatment: "दांतो को सफाई",
      price: "₹ 150",
    },
    tamil: {
      name: "கறைகள்",
      description: "பழுப்பு நிறத்தில் இருந்து கருப்பு நிறமாக மாறுதல்",
      treatment: "பற்களை சுத்தம் செய்தல் மற்றும் பாலிஷ் செய்தல்",
      price: "₹ 150",
    },
    telugu: {
      name: "మరకలు",
      description: "గోధుమ రంగు నుండి నలుపు రంగు మారడం",
      treatment: "పళ్ళు శుభ్రపరచడం మరియు పాలిష్ చేయడం",
      price: "₹ 150",
    },
    ar: {
      name: "صبغات",
      description: "اللون البني إلى اللون الأسود",
      treatment: "تنظيف الأسنان وتلميعها",
      price: "₹ 150",
    },
  },
  calculus: {
    color: "#00FF00",
    gujarati: {
      name: "નળી",
      description: "દાંત/પે ums ા પર સખત પીળી-સફેદ થાપણો",
      treatment: "Deepંડે સ્કેલિંગ",
      price: "₹ 150",
    },
    kannada: {
      name: "ಕಲನಶಾಸ್ತ್ರ",
      description: "ಹಲ್ಲು/ಒಸಡುಗಳ ಮೇಲೆ ಗಟ್ಟಿಯಾದ ಹಳದಿ-ಬಿಳಿ ನಿಕ್ಷೇಪಗಳು",
      treatment: "ಡೀಪ್ ಸ್ಕೇಲಿಂಗ್",
      price: "₹ 150",
    },
    english: {
      name: "Calculus",
      description: "Hard yellowish-white deposits on teeth/gums",
      treatment: "Deep Scaling",
      price: "₹ 150",
    },
    marathi: {
      name: "टार्टर",
      description: "दात/हिरड्यांवर कडक पिवळसर-पांढरा साठा",
      treatment: "संपूर्ण स्वच्छता आणि पॉलिशिंग",
      price: "₹ 150",
    },
    hindi: {
      name: "टार्टर",
      description: "दांतों/मसूड़ों पर सख्त पीला-सफेद जमाव",
      treatment: "गहराई से सफाई",
      price: "₹ 150",
    },
    tamil: {
      name: "கால்குலஸ்",
      description: "பற்கள்/ஈறுகளில் கடினமான மஞ்சள்-வெள்ளை படிவுகள்",
      treatment: "ஆழமான அளவிடுதல்",
      price: "₹ 150",
    },
    telugu: {
      name: "కాలిక్యులస్",
      description: "దంతాలు/చిగుళ్లపై గట్టి పసుపు-తెలుపు నిక్షేపాలు",
      treatment: "డీప్ స్కేలింగ్",
      price: "₹ 150",
    },
    ar: {
      name: "حساب التفاضل والتكامل",
      description: "رواسب صفراء بيضاء على الأسنان/اللثة",
      treatment: "التحجيم العميق",
      price: "₹ 150",
    },
  },
  "dental fluorosis": {
    color: "#FF9A8C",
    gujarati: {
      name: "ફ્લોરોસિસ",
      description: "વધુ ફ્લોરાઇડને કારણે સફેદ ભુરો ફોલ્લીઓ",
      treatment: "બેશરમ",
      price: "₹ 920",
    },
    kannada: {
      name: "ಫ್ಲೋರೋಸಿಸ್",
      description: "ಹೆಚ್ಚುವರಿ ಫ್ಲೋರೈಡ್‌ನಿಂದಾಗಿ ಬಿಳಿ ಕಂದು ಕಲೆಗಳು",
      treatment: "ವೆನಿರ್ / ಕ್ಯಾಪ್",
      price: "₹ 920",
    },
    english: {
      name: "Fluorosis",
      description: "Whitish brown spots due to excess fluoride",
      treatment: "Veneer/ Cap",
      price: "₹ 920",
    },
    marathi: {
      name: "फ्लोरोसिस",
      description: "फ्लोराईड जास्त असल्यामुळे झालेले पांढरे तपकिरी डाग",
      treatment: "विनियर / कैप",
      price: "₹ 920",
    },
    hindi: {
      name: "फ्लोरोसिस",
      description: "अत्यधिक फ्लोराइड के कारण सफेद भूरे रंग के धब्बे",
      treatment: "विनियर / कैप",
      price: "₹ 920",
    },
    tamil: {
      name: "ஃப்ளோரோசிஸ்",
      description:
        "அதிகப்படியான புளோரைடு காரணமாக வெண்மை கலந்த பழுப்பு நிற புள்ளிகள்",
      treatment: "வெனீர் / தொப்பி",
      price: "₹ 920",
    },
    telugu: {
      name: "ఫ్లోరోసిస్",
      description: "అధిక ఫ్లోరైడ్ కారణంగా తెల్లటి గోధుమ రంగు మచ్చలు",
      treatment: "వెనీర్ / క్యాప్",
      price: "₹ 920",
    },
    ar: {
      name: "مرض الفلور",
      description: "بقع بنية بيضاء بسبب الفلوريد الزائد",
      treatment: "قشرة/ غطاء",
      price: "₹ 920",
    },
  },
  "missing tooth": {
    color: "#4B8378",
    gujarati: {
      name: "દાંત ખૂટે છે",
      description: "ગુમ થયેલ દાંતને કારણે અંતર",
      treatment: "રોપવું/ પુલ/ દાંત",
      price: "₹ 10000 / 900 / 200",
    },
    kannada: {
      name: "ಹಲ್ಲು ಕಾಣೆಯಾಗಿದೆ",
      description: "ಕಾಣೆಯಾದ ಹಲ್ಲಿನ ಕಾರಣ ಅಂತರ",
      treatment: "ಇಂಪ್ಲಾಂಟ್ / ಸೇತುವೆ / ದಂತದ್ರವ್ಯ",
      price: "₹ 10000 / 900 / 200",
    },
    english: {
      name: "Missing tooth",
      description: "A gap due to missing tooth",
      treatment: "Implant/ Bridge/ Denture",
      price: "₹ 10000 / 900 / 200",
    },
    marathi: {
      name: "गहाळ दात",
      description: "दात नसल्यामुळे अंतर",
      treatment: "इम्प्लांट / ब्रिज / कवळी",
      price: "₹ 10000 / 900 / 200",
    },
    hindi: {
      name: "दांत में गॅप",
      description: "दांत न होने के कारण गैप",
      treatment: "इम्प्लांट / ब्रिज / डेंचर",
      price: "₹ 10000 / 900 / 200",
    },
    tamil: {
      name: "பல் காணவில்லை",
      description: "பல் இல்லாததால் ஒரு இடைவெளி",
      treatment: "உள்வைப்பு / பாலம் / பல்வகை",
      price: "₹ 10000 / 900 / 200",
    },
    telugu: {
      name: "పంటి లేదు",
      description: "పంటి తప్పిపోయిన కారణంగా గ్యాప్",
      treatment: "ఇంప్లాంట్/ బ్రిడ్జ్/ డెంచర్",
      price: "₹ 10000 / 900 / 200",
    },
    ar: {
      name: "الأسنان المفقودة",
      description: "فجوة بسبب الأسنان المفقودة",
      treatment: "زرع/ جسر/ أسنان",
      price: "₹ 10000 / 900 / 200",
    },
  },
  fracture: {
    color: "#808080",
    gujarati: {
      name: "અસ્થિભંગ",
      description: "અર્ધ અથવા સંપૂર્ણપણે તૂટેલા દાંત",
      treatment: "ભરણ/ ટોપી",
      price: "₹ 200 / 920",
    },
    kannada: {
      name: "ಮುರಿತ",
      description: "ಅರೆ ಅಥವಾ ಸಂಪೂರ್ಣವಾಗಿ ಮುರಿದ ಹಲ್ಲು",
      treatment: "ಭರ್ತಿ / ಕ್ಯಾಪ್",
      price: "₹ 200 / 920",
    },
    english: {
      name: "Fracture",
      description: "Semi or fully broken tooth",
      treatment: "Filling/ Cap",
      price: "₹ 200 / 920",
    },
    marathi: {
      name: "तुटलेला दात",
      description: "अर्धा किंवा पूर्ण तुटलेला दात",
      treatment: "फिलिंग / कॅप",
      price: "₹ 200 / 920",
    },
    hindi: {
      name: "टूटा हुआ दांत",
      description: "आधा या पूरी तरह से टूटा हुआ दांत",
      treatment: "फिलिंग / कैप",
      price: "₹ 200 / 920",
    },
    tamil: {
      name: "எலும்பு முறிவு",
      description: "அரை அல்லது முழுமையாக உடைந்த பல்",
      treatment: "நிரப்புதல் / தொப்பி",
      price: "₹ 200 / 920",
    },
    telugu: {
      name: "ఫ్రాక్చర్",
      description: "సెమీ లేదా పూర్తిగా విరిగిన దంతాలు",
      treatment: "ఫిల్లింగ్ / క్యాప్",
      price: "₹ 200 / 920",
    },
    ar: {
      name: "كسر",
      description: "سن نصف مكسورة بالكامل",
      treatment: "ملء/ غطاء",
      price: "₹ 200 / 920",
    },
  },
  "root stump": {
    color: "#FF00FF",
    gujarati: {
      name: "મૂળિયા",
      description: "મૂળ/દાંતના ટુકડાઓ બાકી",
      treatment: "નિષ્કર્ષણ",
      price: "₹ 40",
    },
    kannada: {
      name: "ರೂಟ್ ಸ್ಟಂಪ್",
      description: "ಬೇರು/ಹಲ್ಲಿನ ತುಂಡುಗಳು ಉಳಿದಿವೆ",
      treatment: "ಹೊರತೆಗೆಯುವಿಕೆ",
      price: "₹ 40",
    },
    english: {
      name: "Root stump",
      description: "Pieces of root/tooth remaining",
      treatment: "Extraction",
      price: "₹ 40",
    },
    marathi: {
      name: "रूट स्टंप",
      description: "कीड खोल असल्यामुळे फक्त दातांचे मूळ वाचले आहे",
      treatment: "दात काढणे",
      price: "₹ 40",
    },
    hindi: {
      name: "रूट स्टंप",
      description: "जड़/दाँत के बचे हुए टुकड़े",
      treatment: "दांत निकालना",
      price: "₹ 40",
    },
    tamil: {
      name: "ரூட் ஸ்டம்ப்",
      description: "மீதமுள்ள வேர்/பல் துண்டுகள்",
      treatment: "பிரித்தெடுத்தல்",
      price: "₹ 40",
    },
    telugu: {
      name: "రూట్ స్టంప్",
      description: "రూట్/టూత్ ముక్కలు మిగిలి ఉన్నాయి",
      treatment: "వెలికితీత",
      price: "₹ 40",
    },
    ar: {
      name: "جذر الجذر",
      description: "قطع الجذر/الأسنان المتبقية",
      treatment: "اِستِخلاص",
      price: "₹ 40",
    },
  },
  "grossly decayed": {
    color: "#FB6090",
    gujarati: {
      name: "એકદમ ક્ષીણ દાંત",
      description: "Deep ંડા પોલાણને કારણે ઓછામાં ઓછું દાંતનું માળખું",
      treatment: "નિષ્કર્ષણ",
      price: "₹ 40",
    },
    kannada: {
      name: "ತೀವ್ರವಾಗಿ ಕೊಳೆತ ಹಲ್ಲು",
      description: "ಆಳವಾದ ಕುಳಿಯಿಂದಾಗಿ ಕನಿಷ್ಠ ಹಲ್ಲಿನ ರಚನೆ ಉಳಿದಿದೆ",
      treatment: "ಹೊರತೆಗೆಯುವಿಕೆ",
      price: "₹ 40",
    },
    english: {
      name: "Grossly decayed tooth",
      description: "Minimum tooth structure left due to deep cavity",
      treatment: "Extraction",
      price: "₹ 40",
    },
    marathi: {
      name: "पूर्ण किडलेला दात",
      description: "कीड खोल गेल्यामुळे दातांचा लहान भाग शिल्लक आहे",
      treatment: "दात काढणे",
      price: "₹ 40",
    },
    hindi: {
      name: "पूरा खराब दांत",
      description:
        "गहरा कीड़ा होने के कारण दांत का केवल एक छोटा सा हिस्सा ही बचा है",
      treatment: "दांत निकालना",
      price: "₹ 40",
    },
    tamil: {
      name: "மொத்தமாக சிதைந்த பல்",
      description:
        "ஆழமான குழியின் காரணமாக குறைந்தபட்ச பல் அமைப்பு எஞ்சியிருக்கிறது",
      treatment: "பிரித்தெடுத்தல்",
      price: "₹ 40",
    },
    telugu: {
      name: "ఘోరంగా క్షీణించిన దంతాలు",
      description: "లోతైన కుహరం కారణంగా మిగిలి ఉన్న కనీస దంతాల నిర్మాణం",
      treatment: "వెలికితీత",
      price: "₹ 40",
    },
    ar: {
      name: "الأسنان المتحللة بشكل صارخ",
      description: "الحد الأدنى من بنية الأسنان المتبقية بسبب التجويف العميق",
      treatment: "اِستِخلاص",
      price: "₹ 40",
    },
  },
  malaligned: {
    color: "#FFFF00",
    gujarati: {
      name: "નબળા",
      description: "કુટિલ દાંત, ગોઠવણીમાં નહીં",
      treatment: "કૌંસ/ સ્પષ્ટ ગોઠવણી કરનાર",
      price: "₹ 15000",
    },
    kannada: {
      name: "ಅಸಮರ್ಪಕ",
      description: "ವಕ್ರ ಹಲ್ಲುಗಳು, ಜೋಡಣೆಯಲ್ಲ",
      treatment: "ಕಟ್ಟುಪಟ್ಟಿಗಳು / ಅಲೈನರ್ ತೆರವುಗೊಳಿಸಿ",
      price: "₹ 15000",
    },
    english: {
      name: "Malaligned",
      description: "Crooked teeth, not in alignment",
      treatment: "Braces/ Clear Aligner",
      price: "₹ 15000",
    },
    marathi: {
      name: "वेडे-वाकडे दात",
      description: "दात संरेखित नाहीत",
      treatment: "ब्रेसेस / क्लिअर अलाइनर",
      price: "₹ 15000",
    },
    hindi: {
      name: "टेढे दांत",
      description: "टेढे मेढे दांत",
      treatment: "ब्रेसेस / क्लियर एलाइनर्स",
      price: "₹ 15000",
    },
    tamil: {
      name: "தவறானது",
      description: "வளைந்த பற்கள், சீரமைப்பில் இல்லை",
      treatment: "பிரேஸ்கள் / தெளிவான சீரமைப்பி",
      price: "₹ 15000",
    },
    telugu: {
      name: "మాలాలైన్డ్",
      description: "వంకర పళ్ళు, సమలేఖనంలో లేవు",
      treatment: "కలుపులు/ క్లియర్ అలైనర్",
      price: "₹ 15000",
    },
    ar: {
      name: "غير لائق",
      description: "الأسنان الملتوية ، وليس في المحاذاة",
      treatment: "الأقواس/ الواضحة",
      price: "₹ 15000",
    },
  },
  attrition: {
    color: "#00FFFF",
    gujarati: {
      name: "આડો",
      description: "ડંખ મારતી સપાટી પર દાંત પહેરવામાં આવે છે",
      treatment: "ભરણ/ નાઇટ ગાર્ડ",
      price: "₹ 200 / 920",
    },
    kannada: {
      name: "ತಿಕ್ಕಾಟ",
      description: "ಕಚ್ಚುವ ಮೇಲ್ಮೈಯಲ್ಲಿ ಹಲ್ಲು ಉದುರಿಹೋಗಿದೆ",
      treatment: "ಭರ್ತಿ / ರಾತ್ರಿ ಗಾರ್ಡ್",
      price: "₹ 200 / 920",
    },
    english: {
      name: "Attrition",
      description: "Worn off tooth on biting surface",
      treatment: "Filling/ Night Guard",
      price: "₹ 200 / 920",
    },
    marathi: {
      name: "दातांची झीज झाली आहे",
      description: "दातांची अत्याधिक झीज झाल्याने ठणक लागू शकते",
      treatment: "फिलिंग / कॅप / नाईट गार्ड",
      price: "₹ 200 / 920",
    },
    hindi: {
      name: "दंत संघर्षण",
      description: "घिसा हुआ दांत",
      treatment: "फिलिंग / नाइट गार्ड",
      price: "₹ 200 / 920",
    },
    tamil: {
      name: "தேய்வு",
      description: "கடிக்கும் மேற்பரப்பில் பல் தேய்ந்தது",
      treatment: "நிரப்புதல் / இரவு காவலர்",
      price: "₹ 200 / 920",
    },
    telugu: {
      name: "అట్రిషన్",
      description: "కొరికే ఉపరితలంపై పంటి అరిగిపోయింది",
      treatment: "ఫిల్లింగ్/ నైట్ గార్డ్",
      price: "₹ 200 / 920",
    },
    ar: {
      name: "الاستنزاف",
      description: "يرتدي الأسنان على سطح العض",
      treatment: "حارس/ حارس ليلي",
      price: "₹ 200 / 920",
    },
  },
  abfraction: {
    color: "#F9B208",
    gujarati: {
      name: "પૂરું",
      description: "ગમ નજીક દાંત પર વી આકારની ખામી",
      treatment: "ભરવા",
      price: "₹ 200",
    },
    kannada: {
      name: "ಅಮೂರ್ತತೆ",
      description: "ಗಮ್ ಬಳಿ ಹಲ್ಲಿನ ಮೇಲೆ ವಿ-ಆಕಾರದ ದೋಷ",
      treatment: "ತುಂಬಿಸುವ",
      price: "₹ 200",
    },
    english: {
      name: "Abfraction",
      description: "V-shaped defect on the tooth near gum",
      treatment: "Filling",
      price: "₹ 200",
    },
    marathi: {
      name: "अब्रेक्शन",
      description: "हिरड्याजवळच्या दातावर V आकाराचा दोष",
      treatment: "फिलिंग",
      price: "₹ 200",
    },
    hindi: {
      name: "अब्रेक्शन",
      description: "वी के आकार का दांत पर घिस जाना",
      treatment: "फिलिंग",
      price: "₹ 200",
    },
    tamil: {
      name: "சுருக்கம்",
      description: "ஈறுக்கு அருகில் உள்ள பல்லில் V வடிவ குறைபாடு",
      treatment: "நிரப்புதல்",
      price: "₹ 200",
    },
    telugu: {
      name: "విక్షేపం",
      description: "చిగుళ్ల దగ్గర పంటిపై V- ఆకారపు లోపం",
      treatment: "నింపడం",
      price: "₹ 200",
    },
    ar: {
      name: "خرب",
      description: "عيب على شكل حرف V على الأسنان بالقرب من اللثة",
      treatment: "حشوة",
      price: "₹ 200",
    },
  },
  diastema: {
    color: "#7c6e61",
    gujarati: {
      name: "ત્રાસ",
      description: "ઉપલા આગળના બે દાંત વચ્ચેનો અંતર",
      treatment: "ભરણ / જાદુઈ",
      price: "₹ 200 / 920",
    },
    kannada: {
      name: "ಡಯಾಸ್ಟೆಮಾ",
      description: "ಮೇಲಿನ ಮುಂಭಾಗದ ಎರಡು ಹಲ್ಲುಗಳ ನಡುವಿನ ಅಂತರ",
      treatment: "ಭರ್ತಿ / ವೆನಿರ್",
      price: "₹ 200 / 920",
    },
    english: {
      name: "Diastema",
      description: "A gap between upper front two teeth",
      treatment: "Filling / Veneer",
      price: "₹ 200 / 920",
    },
    marathi: {
      name: "डायस्टेमा",
      description: "वरच्या दोन दातांमधील अंतर",
      treatment: "विनियर / फिलिंग",
      price: "₹ 200 / 920",
    },
    hindi: {
      name: "सामने के दांतों के बीच फांक",
      description: "सामने के दांतों के बीच फांक",
      treatment: "विनियर / फिलिंग",
      price: "₹ 200 / 920",
    },
    tamil: {
      name: "டயஸ்டெமா",
      description: "மேல் முன் இரண்டு பற்களுக்கு இடையே ஒரு இடைவெளி",
      treatment: "நிரப்புதல் / வெனீர்",
      price: "₹ 200 / 920",
    },
    telugu: {
      name: "డయాస్టెమా",
      description: "ఎగువ ముందు రెండు దంతాల మధ్య అంతరం",
      treatment: "ఫిల్లింగ్ / వెనీర్",
      price: "₹ 200 / 920",
    },
    ar: {
      name: "distema",
      description: "فجوة بين الأسنان الأمامية العلوية",
      treatment: "ملء / قشرة",
      price: "₹ 200 / 920",
    },
  },
  "tooth gap": {
    color: "#3B0918",
    gujarati: {
      name: "દાંતનું અંતર",
      description: "બે દાંત વચ્ચેનો અંતર",
      treatment: "રૂthિગત સારવાર",
      price: "₹ 15000",
    },
    kannada: {
      name: "ಹಲ್ಲಿನ ಅಂತರ",
      description: "ಎರಡು ಹಲ್ಲುಗಳ ನಡುವೆ ಅಂತರ",
      treatment: "ಆರ್ಥೊಡಾಂಟಿಕ್ ಚಿಕಿತ್ಸೆ",
      price: "₹ 15000",
    },
    english: {
      name: "Tooth gap",
      description: "A gap between two teeth",
      treatment: "Orthodontic treatment",
      price: "₹ 15000",
    },
    marathi: {
      name: "दोन दातांमधील फट",
      description: "दातांमधील अंतर",
      treatment: "ब्रेसेस / क्लिअर अलाइनर",
      price: "₹ 15000",
    },
    hindi: {
      name: "दो दांतों के बीच फांक",
      description: "दो दांतों के बीच फांक",
      treatment: "दांतो को सीधा करने की इलाज",
      price: "₹ 15000",
    },
    tamil: {
      name: "பல் இடைவெளி",
      description: "இரண்டு பற்களுக்கு இடையே ஒரு இடைவெளி",
      treatment: "ஆர்த்தோடோன்டிக் சிகிச்சை",
      price: "₹ 15000",
    },
    telugu: {
      name: "పంటి గ్యాప్",
      description: "రెండు దంతాల మధ్య అంతరం",
      treatment: "ఆర్థోడోంటిక్ చికిత్స",
      price: "₹ 15000",
    },
    ar: {
      name: "فجوة الأسنان",
      description: "فجوة بين اثنين من الأسنان",
      treatment: "المعالجة التقويمية",
      price: "₹ 15000",
    },
  },
  tooth_gap: {
    color: "#3B0918",
    gujarati: {
      name: "દાંતનું અંતર",
      description: "બે દાંત વચ્ચેનો અંતર",
      treatment: "રૂthિગત સારવાર",
      price: "₹ 15000",
    },
    kannada: {
      name: "ಹಲ್ಲಿನ ಅಂತರ",
      description: "ಎರಡು ಹಲ್ಲುಗಳ ನಡುವೆ ಅಂತರ",
      treatment: "ಆರ್ಥೊಡಾಂಟಿಕ್ ಚಿಕಿತ್ಸೆ",
      price: "₹ 15000",
    },
    english: {
      name: "Tooth gap",
      description: "A gap between two teeth",
      treatment: "Orthodontic treatment",
      price: "₹ 15000",
    },
    marathi: {
      name: "दोन दातांमधील फट",
      description: "दातांमधील अंतर",
      treatment: "ब्रेसेस / क्लिअर अलाइनर",
      price: "₹ 15000",
    },
    hindi: {
      name: "दो दांतों के बीच फांक",
      description: "दो दांतों के बीच फांक",
      treatment: "दांतो को सीधा करने की इलाज",
      price: "₹ 15000",
    },
    tamil: {
      name: "பல் இடைவெளி",
      description: "இரண்டு பற்களுக்கு இடையே ஒரு இடைவெளி",
      treatment: "ஆர்த்தோடோன்டிக் சிகிச்சை",
      price: "₹ 15000",
    },
    telugu: {
      name: "పంటి గ్యాప్",
      description: "రెండు దంతాల మధ్య అంతరం",
      treatment: "ఆర్థోడోంటిక్ చికిత్స",
      price: "₹ 15000",
    },
    ar: {
      name: "فجوة الأسنان",
      description: "فجوة بين اثنين من الأسنان",
      treatment: "المعالجة التقويمية",
      price: "₹ 15000",
    },
  },
  "silver filling": {
    color: "#73777B",
    gujarati: {
      name: "ચાંદી ભરવા",
      description: "બ્લેક કલર ભરવાનું જે લાંબા ગાળે નુકસાનકારક હોઈ શકે છે",
      treatment: "સલામત દાંતના રંગીન ભરવા સાથે બદલો",
      price: "₹ 200 - 350",
    },
    kannada: {
      name: "ಬೆಳ್ಳಿ ತುಂಬುವುದು",
      description: "ಕಪ್ಪು ಬಣ್ಣ ತುಂಬುವಿಕೆಯು ದೀರ್ಘಾವಧಿಯಲ್ಲಿ ಹಾನಿಗೊಳಗಾಗಬಹುದು",
      treatment: "ಸುರಕ್ಷಿತ ಹಲ್ಲಿನ ಬಣ್ಣದ ತುಂಬುವಿಕೆಯೊಂದಿಗೆ ಬದಲಾಯಿಸಿ",
      price: "₹ 200 - 350",
    },
    english: {
      name: "Silver filling",
      description: "Black color filling which can be damaging in long run",
      treatment: "Replace with safe tooth colored filling",
      price: "₹ 200 - 350",
    },
    marathi: {
      name: "चांदी भरलेला दात",
      description: "चांदी मध्ये असलेला पारा हानिकारक आहे",
      treatment: "टूथ कलर फिलिंग्सने बदलून घ्या",
      price: "₹ 200 - 350",
    },
    hindi: {
      name: "सिल्वर फिलिंग",
      description: "चांदी/काले रंग का दाँत फिलिंग",
      treatment: "सुरक्षित दांत के रंग के फिलिंग से बदलें",
      price: "₹ 200 - 350",
    },
    tamil: {
      name: "வெள்ளி நிரப்புதல்",
      description: "கருப்பு நிற நிரப்புதல் நீண்ட காலத்திற்கு சேதமடையலாம்",
      treatment: "பாதுகாப்பான பல் நிற நிரப்புதலுடன் மாற்றவும்",
      price: "₹ 200 - 350",
    },
    telugu: {
      name: "వెండి నింపడం",
      description: "బ్లాక్ కలర్ ఫిల్లింగ్ దీర్ఘకాలంలో దెబ్బతింటుంది",
      treatment: "సురక్షితమైన టూత్ కలర్ ఫిల్లింగ్తో భర్తీ చేయండి",
      price: "₹ 200 - 350",
    },
    ar: {
      name: "ملء الفضة",
      description: "ملء اللون الأسود الذي يمكن أن يكون ضارًا على المدى الطويل",
      treatment: "استبدل بملء آمن للأسنان الملونة",
      price: "₹ 200 - 350",
    },
  },
  "composite filling": {
    color: "#C85C5C",
    gujarati: {
      name: "સંયુક્ત ભરણ",
      description: "દાંતનો રંગ ભરવો",
      treatment: "જો તૂટે તો બદલો",
      price: "₹ 350",
    },
    kannada: {
      name: "ಸಂಯೋಜಿತ ಭರ್ತಿ",
      description: "ಹಲ್ಲಿನ ಬಣ್ಣ ತುಂಬುವುದು",
      treatment: "ಮುರಿದರೆ ಬದಲಾಯಿಸಿ",
      price: "₹ 350",
    },
    english: {
      name: "Composite filling",
      description: "Tooth colored filling",
      treatment: "Replace if broken",
      price: "₹ 350",
    },
    marathi: {
      name: "कम्पोजिट फीलिंग",
      description: "दातासमान रंग असणारी फिलिंग",
      treatment: "तुटल्यास बदला",
      price: "₹ 350",
    },
    hindi: {
      name: "कम्पोजिट फीलिंग",
      description: "दांतों के रंग का भराव",
      treatment: "अगर टूटा हुआ है तो बदलें",
      price: "₹ 350",
    },
    tamil: {
      name: "கலப்பு நிரப்புதல்",
      description: "பல் நிற நிரப்புதல்",
      treatment: "உடைந்தால் மாற்றவும்",
      price: "₹ 350",
    },
    telugu: {
      name: "మిశ్రమ పూరకం",
      description: "పంటి రంగు నింపడం",
      treatment: "విచ్ఛిన్నమైతే భర్తీ చేయండి",
      price: "₹ 350",
    },
    ar: {
      name: "مركب ملء",
      description: "حشوة ملونة الأسنان",
      treatment: "استبدال إذا كسر",
      price: "₹ 350",
    },
  },
  "metal crown": {
    color: "#FF0075",
    gujarati: {
      name: "ધાતુનો તાજ",
      description: "કાળી ધાતુ",
      treatment: "તેને નિરીક્ષણ હેઠળ રાખો",
      price: "FREE scan on DentalDost",
    },
    kannada: {
      name: "ಲೋಹದ ಕಿರೀಟ",
      description: "ಕಪ್ಪು ಲೋಹದ ಕ್ಯಾಪ್",
      treatment: "ಅದನ್ನು ವೀಕ್ಷಣೆಯಲ್ಲಿ ಇರಿಸಿ",
      price: "DentalDost ನಲ್ಲಿ ಉಚಿತ ಸ್ಕ್ಯಾನ್",
    },
    english: {
      name: "Metal crown",
      description: "Black metal cap",
      treatment: "Keep it under observation",
      price: "FREE scan on DentalDost",
    },
    marathi: {
      name: "ब्लॅक मेटल कॅप",
      description: "काळ्या धातूचा कॅप",
      treatment: "निरीक्षणाखाली ठेवा",
      price: "डेंटलडॉस्टवर विनामूल्य स्कॅन",
    },
    hindi: {
      name: "मेटल कैप",
      description: "काली धातु के कैप",
      treatment: "निगरानी में रखें",
      price: "डेंटलडॉस्ट पर मुफ्त स्कैन",
    },
    tamil: {
      name: "உலோக கிரீடம்",
      description: "கருப்பு உலோக தொப்பி",
      treatment: "அதை கண்காணிப்பில் வைக்கவும்",
      price: "DentalDost இல் இலவச ஸ்கேன்",
    },
    telugu: {
      name: "మెటల్ కిరీటం",
      description: "బ్లాక్ మెటల్ టోపీ",
      treatment: "పరిశీలనలో ఉంచండి",
      price: "DentalDostలో ఉచిత స్కాన్",
    },
    ar: {
      name: "التاج المعدني",
      description: "غطاء معدني أسود",
      treatment: "احتفظ بها تحت الملاحظة",
      price: "FREE scan on DentalDost",
    },
  },
  "ceramic crown": {
    color: "#77D970",
    gujarati: {
      name: "કળણનો તાજ",
      description: "દાંત",
      treatment: "તેને નિરીક્ષણ હેઠળ રાખો",
      price: "FREE scan on DentalDost",
    },
    kannada: {
      name: "ಸೆರಾಮಿಕ್ ಕಿರೀಟ",
      description: "ಹಲ್ಲಿನ ಬಣ್ಣದ ಕ್ಯಾಪ್",
      treatment: "ಅದನ್ನು ವೀಕ್ಷಣೆಯಲ್ಲಿ ಇರಿಸಿ",
      price: "DentalDost ನಲ್ಲಿ ಉಚಿತ ಸ್ಕ್ಯಾನ್",
    },
    english: {
      name: "Ceramic crown",
      description: "Tooth coloured cap",
      treatment: "Keep it under observation",
      price: "FREE scan on DentalDost",
    },
    marathi: {
      name: "सिरेमिक कॅप",
      description: "दातासमान रंग असणारी कॅप",
      treatment: "निरीक्षणाखाली ठेवा",
      price: "डेंटलडॉस्टवर विनामूल्य स्कॅन",
    },
    hindi: {
      name: "सिरेमिक कैप",
      description: "दांत के रंग का कैप",
      treatment: "निगरानी में रखें",
      price: "डेंटलडॉस्ट पर मुफ्त स्कैन",
    },
    tamil: {
      name: "பீங்கான் கிரீடம்",
      description: "பல் நிற தொப்பி",
      treatment: "அதை கண்காணிப்பில் வைக்கவும்",
      price: "DentalDost இல் இலவச ஸ்கேன்",
    },
    telugu: {
      name: "సిరామిక్ కిరీటం",
      description: "పంటి రంగు టోపీ",
      treatment: "పరిశీలనలో ఉంచండి",
      price: "DentalDostలో ఉచిత స్కాన్",
    },
    ar: {
      name: "تاج الخزف",
      description: "غطاء ملون الأسنان",
      treatment: "احتفظ بها تحت الملاحظة",
      price: "FREE scan on DentalDost",
    },
  },
  abrasion: {
    color: "#774360",
    gujarati: {
      name: "ઘર્ષણ",
      description: "દાંતની આગળની સપાટીની ચપટી",
      treatment: "દાંતનો રંગ ભરવો",
      price: "₹ 350",
    },
    kannada: {
      name: "ಸವೆತ",
      description: "ಹಲ್ಲಿನ ಮುಂಭಾಗದ ಮೇಲ್ಮೈಯನ್ನು ಚಪ್ಪಟೆಗೊಳಿಸುವುದು",
      treatment: "ಹಲ್ಲಿನ ಬಣ್ಣ ತುಂಬುವುದು",
      price: "₹ 350",
    },
    english: {
      name: "Abrasion",
      description: "Flattening of front surface of the tooth",
      treatment: "Tooth colour filling",
      price: "₹ 350",
    },
    marathi: {
      name: "दातांचे घर्षण",
      description: "दातांच्या समोरील भागाचे घर्षण",
      treatment: "दातासमान फिलिंग",
      price: "₹ 350",
    },
    hindi: {
      name: "दांतों का घर्षण",
      description: "दांतों के सामने की सतह का चपटा होना",
      treatment: "दांतो के रंग के फिलिंग",
      price: "₹ 350",
    },
    tamil: {
      name: "சிராய்ப்பு",
      description: "பல்லின் முன் மேற்பரப்பைத் தட்டையாக்குதல்",
      treatment: "பல் நிறத்தை நிரப்புதல்",
      price: "₹ 350",
    },
    telugu: {
      name: "రాపిడి",
      description: "దంతాల ముందు ఉపరితలం చదును చేయడం",
      treatment: "పంటి రంగు నింపడం",
      price: "₹ 350",
    },
    ar: {
      name: "تآكل",
      description: "تسطيح السطح الأمامي للسن",
      treatment: "ملء لون الأسنان",
      price: "₹ 350",
    },
  },
  denture: {
    color: "#A64B2A",
    gujarati: {
      name: "ડેન્ચર",
      description: "કૃત્રિમ દાંતનો સમૂહ",
      treatment: "સ્વચ્છતા જાળવી",
      price: "FREE scan on DentalDost",
    },
    kannada: {
      name: "ದಂತಪಂಕ್ತಿ",
      description: "ಹಲ್ಲುಗಳ ಕೃತಕ ಸೆಟ್",
      treatment: "ನೈರ್ಮಲ್ಯವನ್ನು ಕಾಪಾಡಿಕೊಳ್ಳಿ",
      price: "DentalDost ನಲ್ಲಿ ಉಚಿತ ಸ್ಕ್ಯಾನ್",
    },
    english: {
      name: "Denture",
      description: "Artificial set of teeth",
      treatment: "Maintain hygiene",
      price: "FREE scan on DentalDost",
    },
    marathi: {
      name: "कवळी",
      description: "खोट्या दातांनी बनवलेले कवळी",
      treatment: "स्वच्छता राखणे",
      price: "डेंटलडॉस्टवर विनामूल्य स्कॅन",
    },
    hindi: {
      name: "नकली दांत",
      description: "कृत्रिम दांतों की पंक्ति",
      treatment: "स्वच्छता बनाए रखें",
      price: "डेंटलडॉस्ट पर मुफ्त स्कैन",
    },
    tamil: {
      name: "பல்வகை",
      description: "செயற்கை பற்களின் தொகுப்பு",
      treatment: "சுகாதாரத்தை பேணுங்கள்",
      price: "DentalDost இல் இலவச ஸ்கேன்",
    },
    telugu: {
      name: "కట్టుడు పళ్ళు",
      description: "దంతాల కృత్రిమ సెట్",
      treatment: "పరిశుభ్రత పాటించండి",
      price: "DentalDostలో ఉచిత స్కాన్",
    },
    ar: {
      name: "أسنان",
      description: "مجموعة مصطنعة من الأسنان",
      treatment: "الحفاظ على النظافة",
      price: "FREE scan on DentalDost",
    },
  },
  appliances: {
    color: "#B25068",
    gujarati: {
      name: "અરજીઓ",
      description: "ઓર્થો ટ્રીટમેન્ટ રીટેનર પોસ્ટ",
      treatment: "સ્વચ્છતા જાળવી",
      price: "FREE scan on DentalDost",
    },
    kannada: {
      name: "ಉಪಕರಣಗಳು",
      description: "ಪೋಸ್ಟ್ ಆರ್ಥೋ ಚಿಕಿತ್ಸೆಯ ಧಾರಕ",
      treatment: "ನೈರ್ಮಲ್ಯವನ್ನು ಕಾಪಾಡಿಕೊಳ್ಳಿ",
      price: "DentalDost ನಲ್ಲಿ ಉಚಿತ ಸ್ಕ್ಯಾನ್",
    },
    english: {
      name: "Appliances",
      description: "Post ortho treatment retainer",
      treatment: "Maintain hygiene",
      price: "FREE scan on DentalDost",
    },
    marathi: {
      name: "उपकरण",
      description: "ऑर्थो ट्रीटमेंट झाल्यानंतर असलेले उपकरण",
      treatment: "स्वच्छता राखणे",
      price: "डेंटलडॉस्टवर विनामूल्य स्कॅन",
    },
    hindi: {
      name: "उपकरण",
      description: "ऑर्थो ट्रीटमेंट के बाद रिटेनर",
      treatment: "स्वच्छता बनाए रखें",
      price: "डेंटलडॉस्ट पर मुफ्त स्कैन",
    },
    tamil: {
      name: "உபகரணங்கள்",
      description: "பிந்தைய ஆர்த்தோ சிகிச்சை தக்கவைப்பவர்",
      treatment: "சுகாதாரத்தை பேணுங்கள்",
      price: "DentalDost இல் இலவச ஸ்கேன்",
    },
    telugu: {
      name: "గృహోపకరణాలు",
      description: "పోస్ట్ ఆర్థో ట్రీట్మెంట్ రిటైనర్",
      treatment: "పరిశుభ్రత పాటించండి",
      price: "DentalDostలో ఉచిత స్కాన్",
    },
    ar: {
      name: "الأجهزة",
      description: "ما بعد التجنيب علاج Ortho",
      treatment: "الحفاظ على النظافة",
      price: "FREE scan on DentalDost",
    },
  },
  "metal ceramic": {
    color: "#DACC96",
    gujarati: {
      name: "ધાતુના સિરામિક તાજ",
      description: "મેટલ અસ્તર સાથે દાંત રંગની કેપ",
      treatment: "તેને નિરીક્ષણ હેઠળ રાખો",
      price: "FREE scan on DentalDost",
    },
    kannada: {
      name: "ಲೋಹದ ಸೆರಾಮಿಕ್ ಕಿರೀಟ",
      description: "ಲೋಹದ ಒಳಪದರದೊಂದಿಗೆ ಹಲ್ಲಿನ ಬಣ್ಣದ ಕ್ಯಾಪ್",
      treatment: "ಅದನ್ನು ವೀಕ್ಷಣೆಯಲ್ಲಿ ಇರಿಸಿ",
      price: "DentalDost ನಲ್ಲಿ ಉಚಿತ ಸ್ಕ್ಯಾನ್",
    },
    english: {
      name: "Metal ceramic crown",
      description: "Tooth colored cap with metal lining",
      treatment: "Keep it under observation",
      price: "FREE scan on DentalDost",
    },
    marathi: {
      name: "मेटल सिरेमिक कॅप",
      description: "धातूच्या अस्तरांसह दात रंगीत कॅप",
      treatment: "निरीक्षणाखाली ठेवा",
      price: "डेंटलडॉस्टवर विनामूल्य स्कॅन",
    },
    hindi: {
      name: "मेटल सिरेमिक कैप",
      description: "चाँदी लाइनिंग के साथ दाँत कलर कैप",
      treatment: "निगरानी में रखें",
      price: "डेंटलडॉस्ट पर मुफ्त स्कैन",
    },
    tamil: {
      name: "உலோக பீங்கான் கிரீடம்",
      description: "உலோகப் புறணியுடன் கூடிய பல் நிற தொப்பி",
      treatment: "அதை கண்காணிப்பில் வைக்கவும்",
      price: "DentalDost இல் இலவச ஸ்கேன்",
    },
    telugu: {
      name: "మెటల్ సిరామిక్ కిరీటం",
      description: "మెటల్ లైనింగ్‌తో టూత్ కలర్ క్యాప్",
      treatment: "పరిశీలనలో ఉంచండి",
      price: "DentalDostలో ఉచిత స్కాన్",
    },
    ar: {
      name: "تاج السيراميك المعدني",
      description: "غطاء ملون الأسنان مع بطانة معدنية",
      treatment: "احتفظ بها تحت الملاحظة",
      price: "FREE scan on DentalDost",
    },
  },
  "impacted 3rd molar / pericoronitis": {
    color: "#6D8B74",
    gujarati: {
      name: "અસરગ્રસ્ત ત્રીજી દા ol",
      description: "આંશિક રૂપે દૃશ્યમાન અથવા દૃશ્યમાન શાણપણ દાંત",
      treatment: "નિષ્કર્ષણ",
      price: "₹ 100 - 500",
    },
    kannada: {
      name: "ಪ್ರಭಾವಿತ ಮೂರನೇ ಮೋಲಾರ್",
      description: "ಭಾಗಶಃ ಗೋಚರಿಸುವ ಅಥವಾ ಗೋಚರಿಸದ ಬುದ್ಧಿವಂತಿಕೆಯ ಹಲ್ಲು",
      treatment: "ಹೊರತೆಗೆಯುವಿಕೆ",
      price: "₹ 100 - 500",
    },
    english: {
      name: "Impacted third molar",
      description: "Partially visible or not visible wisdom tooth",
      treatment: "Extraction",
      price: "₹ 100 - 500",
    },
    marathi: {
      name: "अक्कल दाढ",
      description: "अक्कल दाढ, अर्धी किंवा पुर्णपणे जबड्या मध्ये रूतलेली आहे",
      treatment: "अक्कल दाढ काढावी लागेल",
      price: "₹ 100 - 500",
    },
    hindi: {
      name: "अक्ल दाढ़",
      description: "आंशिक रूप से दिखाई देने वाला या न दिखाई देने वाला अकल दाढ़",
      treatment: "दांत निकालना",
      price: "₹ 100 - 500",
    },
    tamil: {
      name: "பாதிக்கப்பட்ட மூன்றாவது மோலார்",
      description: "ஓரளவு தெரியும் அல்லது தெரியாத ஞானப் பல்",
      treatment: "பிரித்தெடுத்தல்",
      price: "₹ 100 - 500",
    },
    telugu: {
      name: "మూడవ మోలార్ ప్రభావితమైంది",
      description: "పాక్షికంగా కనిపించే లేదా కనిపించని జ్ఞాన దంతాలు",
      treatment: "వెలికితీత",
      price: "₹ 100 - 500",
    },
    ar: {
      name: "تأثر المولي الثالث",
      description: "مرئية جزئيا أو غير مرئية الأسنان الحكمة",
      treatment: "اِستِخلاص",
      price: "₹ 100 - 500",
    },
  },
  "discolored tooth": {
    color: "#F9CEEE",
    gujarati: {
      name: "વિકૃત દાંત",
      description: "સામાન્ય કરતાં દાંતનો રંગ બદલાયો",
      treatment: "મૂળ નહેરની સારવાર",
      price: "₹ 990+",
    },
    kannada: {
      name: "ಬಣ್ಣಬಣ್ಣದ ಹಲ್ಲು",
      description: "ಹಲ್ಲಿನ ಬಣ್ಣವನ್ನು ಸಾಮಾನ್ಯಕ್ಕಿಂತ ಬದಲಾಯಿಸಲಾಗಿದೆ",
      treatment: "ಮೂಲ ಕಾಲುವೆ ಚಿಕಿತ್ಸೆ",
      price: "₹ 990+",
    },
    english: {
      name: "Discoloured tooth",
      description: "Changed tooth color than normal",
      treatment: "Root canal treatment",
      price: "₹ 990+",
    },
    marathi: {
      name: "रंग बदललेला दात",
      description: "दातांना मार लागल्यामुळे रंग बदल झालेला दात",
      treatment: "रूट कॅनल उपचार",
      price: "₹ 990+",
    },
    hindi: {
      name: "फीका पड़ा हुआ दाँत",
      description: "बदले हुए दांतो के रंग",
      treatment: "रुट कैनाल उपचार",
      price: "₹ 990+",
    },
    tamil: {
      name: "நிறம் மாறிய பல்",
      description: "இயல்பை விட பல் நிறம் மாறிவிட்டது",
      treatment: "ரூட் கால்வாய் சிகிச்சை",
      price: "₹ 990+",
    },
    telugu: {
      name: "రంగు మారిన పంటి",
      description: "సాధారణం కంటే పంటి రంగు మార్చబడింది",
      treatment: "రూట్ కెనాల్ చికిత్స",
      price: "₹ 990+",
    },
    ar: {
      name: "الأسنان الملونة",
      description: "تغيير لون الأسنان من المعتاد",
      treatment: "معالجة قناة الجذر",
      price: "₹ 990+",
    },
  },
  "food lodgement": {
    color: "#ef6e29",
    gujarati: {
      name: "ખાદ્ય -લોજમેન્ટ",
      description:
        "ખોરાક તમારા દાંતમાં અને વચ્ચે અટવાઇ જાય છે, દાંતના સડો સૂચવી શકે છે",
      treatment: "ભરવા",
      price: "₹ 200 - 350",
    },
    kannada: {
      name: "ಆಹಾರ ವಸತಿ",
      description:
        "ಆಹಾರವು ನಿಮ್ಮ ಹಲ್ಲುಗಳಲ್ಲಿ ಮತ್ತು ಹಲ್ಲುಗಳ ನಡುವೆ ಅಂಟಿಕೊಂಡಿರುತ್ತದೆ, ಇದು ಹಲ್ಲಿನ ಕೊಳೆತವನ್ನು ಸೂಚಿಸುತ್ತದೆ",
      treatment: "ತುಂಬಿಸುವ",
      price: "₹ 200 - 350",
    },
    english: {
      name: "Food lodgement",
      description:
        "Food is stuck in and between your teeth, can indicate tooth decay",
      treatment: "Filling",
      price: "₹ 200 - 350",
    },
    marathi: {
      name: "दांतांमध्ये अन्न अडकलेले आहे",
      description: "दातामद्धे अन्न अडकत असल्यामुळे कीड असण्याची शक्यता आहे",
      treatment: "फिलिंग",
      price: "₹ 200 - 350",
    },
    hindi: {
      name: "दांतों में फंसा खाना",
      description: "खाना आपके दांतों में और उनके बीच फंसा हुआ है",
      treatment: "फिलिंग",
      price: "₹ 200 - 350",
    },
    tamil: {
      name: "உணவு தங்குமிடம்",
      description:
        "உங்கள் பற்களுக்கு இடையில் உணவு சிக்கிக்கொண்டால், அது பல் சிதைவைக் குறிக்கலாம்",
      treatment: "நிரப்புதல்",
      price: "₹ 200 - 350",
    },
    telugu: {
      name: "ఆహార వసతి",
      description:
        "ఆహారం మీ దంతాలలో మరియు వాటి మధ్య ఇరుక్కుపోయి ఉంటే, దంత క్షయాన్ని సూచిస్తుంది",
      treatment: "నింపడం",
      price: "₹ 200 - 350",
    },
    ar: {
      name: "إقامة الطعام",
      description: "الطعام عالق في وبين أسنانك ، يمكن أن يشير إلى تسوس الأسنان",
      treatment: "حشوة",
      price: "₹ 200 - 350",
    },
  },
  braces: {
    color: "#F5A7A7",
    gujarati: {
      name: "કૌંસ",
      description: "ચાલુ ઓર્થો સારવાર",
      treatment: "સ્વચ્છતા જાળવી",
      price: "FREE scan on DentalDost",
    },
    kannada: {
      name: "ಕಟ್ಟುಪಟ್ಟಿಗಳು",
      description: "ನಡೆಯುತ್ತಿರುವ ಆರ್ಥೋ ಚಿಕಿತ್ಸೆ",
      treatment: "ನೈರ್ಮಲ್ಯವನ್ನು ಕಾಪಾಡಿಕೊಳ್ಳಿ",
      price: "DentalDost ನಲ್ಲಿ ಉಚಿತ ಸ್ಕ್ಯಾನ್",
    },
    english: {
      name: "Braces",
      description: "Ongoing ortho treatment",
      treatment: "Maintain hygiene",
      price: "FREE scan on DentalDost",
    },
    marathi: {
      name: "ऑर्थो ब्रेसेस",
      description: "ऑर्थो उपचार सुरू आहे",
      treatment: "स्वच्छता राखणे",
      price: "डेंटलडॉस्टवर विनामूल्य स्कॅन",
    },
    hindi: {
      name: "दांतो में तार",
      description: "ऑर्थो का इलाज चल रहा है",
      treatment: "स्वच्छता बनाए रखें",
      price: "डेंटलडॉस्ट पर मुफ्त स्कैन",
    },
    tamil: {
      name: "பிரேஸ்கள்",
      description: "தொடர்ந்து ஆர்த்தோ சிகிச்சை",
      treatment: "சுகாதாரத்தை பேணுங்கள்",
      price: "DentalDost இல் இலவச ஸ்கேன்",
    },
    telugu: {
      name: "జంట కలుపులు",
      description: "కొనసాగుతున్న ఆర్థో చికిత్స",
      treatment: "పరిశుభ్రత పాటించండి",
      price: "DentalDostలో ఉచిత స్కాన్",
    },
    ar: {
      name: "الأقواس",
      description: "علاج Ortho المستمر",
      treatment: "الحفاظ على النظافة",
      price: "FREE scan on DentalDost",
    },
  },
  "receded gums": {
    color: "#AC7D88",
    gujarati: {
      name: "દહન",
      description: "ગુંદર બદલવાની સ્થિતિને કારણે મૂળનો એક ભાગ દેખાય છે",
      treatment: "ગમ સર્જરી",
      price: "₹ 300",
    },
    kannada: {
      name: "ಹಿಮ್ಮೆಟ್ಟಿಸಿದ ಒಸಡುಗಳು",
      description:
        "ಒಸಡುಗಳು ಸ್ಥಾನವನ್ನು ಬದಲಾಯಿಸುವುದರಿಂದ ಬೇರಿನ ಒಂದು ಭಾಗವು ಗೋಚರಿಸುತ್ತದೆ",
      treatment: "ಗಮ್ ಸರ್ಜರಿ",
      price: "₹ 300",
    },
    english: {
      name: "Receded gums",
      description: "A part of root is visible due to gums changing position",
      treatment: "Gum Surgery",
      price: "₹ 300",
    },
    marathi: {
      name: "मूळ उघडे पडले आहे",
      description:
        "हिरड्यांची उंची कमी होते, लाल, सुजणे ही सुरुवातीची लक्षणे दिसतात",
      treatment: "हिरड्यांची शस्त्रक्रिया",
      price: "₹ 300",
    },
    hindi: {
      name: "दांतों की जड़ें उजागर हैं",
      description: "जड़ का एक भाग दिखाई देता है",
      treatment: "मसूड़े की सर्जरी",
      price: "₹ 300",
    },
    tamil: {
      name: "பின்வாங்கிய ஈறுகள்",
      description: "ஈறுகளின் நிலை மாறுவதால் வேரின் ஒரு பகுதி தெரியும்",
      treatment: "ஈறு அறுவை சிகிச்சை",
      price: "₹ 300",
    },
    telugu: {
      name: "చిగుళ్ళు తగ్గాయి",
      description: "చిగుళ్ల స్థానం మారడం వల్ల రూట్‌లో కొంత భాగం కనిపిస్తుంది",
      treatment: "గమ్ సర్జరీ",
      price: "₹ 300",
    },
    ar: {
      name: "تراجعت اللثة",
      description: "جزء من الجذر مرئي بسبب تغيير الموقف اللثة",
      treatment: "جراحة اللثة",
      price: "₹ 300",
    },
  },
  mesiodens: {
    color: "#82C4C3",
    gujarati: {
      name: "મેસિઓડન્સ",
      description: "ઉપરના બે દાંત વચ્ચે વધારાના દાંત હાજર",
      treatment: "નિષ્કર્ષણ",
      price: "₹ 40",
    },
    kannada: {
      name: "ಮೆಸಿಯೊಡೆನ್ಸ್",
      description: "ಮೇಲಿನ ಎರಡು ಮುಂಭಾಗದ ಹಲ್ಲುಗಳ ನಡುವೆ ಹೆಚ್ಚುವರಿ ಹಲ್ಲು ಇರುತ್ತದೆ",
      treatment: "ಹೊರತೆಗೆಯುವಿಕೆ",
      price: "₹ 40",
    },
    english: {
      name: "Mesiodens",
      description: "Extra tooth present between upper two front teeth",
      treatment: "Extraction",
      price: "₹ 40",
    },
    marathi: {
      name: "अतिरिक्त दात",
      description: "हा एक अतिरिक्त दात आहे",
      treatment: "काढावी लागेल",
      price: "₹ 40",
    },
    hindi: {
      name: "अतिरिक्त दांत",
      description: "यह दांत अतिरिक्त है",
      treatment: "दांत निकालना",
      price: "₹ 40",
    },
    tamil: {
      name: "கூடுதல் பல்",
      description: "மேல் இரண்டு முன் பற்களுக்கு இடையில் கூடுதல் பல் உள்ளது",
      treatment: "பிரித்தெடுத்தல்",
      price: "₹ 40",
    },
    telugu: {
      name: "మెసియోడెన్స్",
      description: "ఎగువ రెండు ముందు దంతాల మధ్య అదనపు దంతాలు ఉన్నాయి",
      treatment: "వెలికితీత",
      price: "₹ 40",
    },
    ar: {
      name: "mesiodens",
      description: "الأسنان الإضافية بين الأسنان الأمامية العلوية",
      treatment: "اِستِخلاص",
      price: "₹ 40",
    },
  },
  "incomplete rct": {
    color: "#BF9742",
    gujarati: {
      name: "અપૂર્ણ આર.સી.ટી.",
      description: "રુટ કેનાલ સારવાર પૂર્ણ નથી અને દાંત માટે ખૂબ જ હાનિકારક છે",
      treatment: "ભરણ અને કેપ",
      price: "₹ 990 - 1140",
    },
    kannada: {
      name: "ಅಪೂರ್ಣ RCT",
      description:
        "ರೂಟ್ ಕೆನಾಲ್ ಚಿಕಿತ್ಸೆಯು ಪೂರ್ಣಗೊಂಡಿಲ್ಲ ಮತ್ತು ಹಲ್ಲಿಗೆ ತುಂಬಾ ಹಾನಿಕಾರಕವಾಗಿದೆ",
      treatment: "ಭರ್ತಿ ಮತ್ತು ಕ್ಯಾಪ್",
      price: "₹ 990 - 1140",
    },
    english: {
      name: "Incomplete RCT",
      description:
        "Root canal treatment is not complete and very harmful for the tooth",
      treatment: "Filling & Cap",
      price: "₹ 990 - 1140",
    },
    marathi: {
      name: "अपूर्ण रूट कॅनॉल ट्रीटमेंट",
      description:
        "अपूर्ण रूट कॅनॉल झाले असल्यास दातांमध्ये पुनः संक्रमण होऊ शकतो",
      treatment: "पूर्ण रूट कॅनाल उपचार",
      price: "₹ 990 - 1140",
    },
    hindi: {
      name: "अपूर्ण रूट कैनाल उपचार",
      description: "अपूर्ण रूट कैनाल उपचार जो दांतों के लिए हानिकारक होता है",
      treatment: "फिलिंग और कैप",
      price: "₹ 990 - 1140",
    },
    tamil: {
      name: "முழுமையற்ற RCT",
      description:
        "ரூட் கால்வாய் சிகிச்சை முழுமையடையாது மற்றும் பல்லுக்கு மிகவும் தீங்கு விளைவிக்கும்",
      treatment: "நிரப்புதல் & தொப்பி",
      price: "₹ 990 - 1140",
    },
    telugu: {
      name: "అసంపూర్ణమైన RCT",
      description: "రూట్ కెనాల్ చికిత్స పూర్తి కాదు మరియు పంటికి చాలా హానికరం",
      treatment: "ఫిల్లింగ్ & క్యాప్",
      price: "₹ 990 - 1140",
    },
    ar: {
      name: "RCT غير مكتمل",
      description: "علاج قناة الجذر غير مكتمل وضارة للغاية للسن",
      treatment: "ملء و CAP",
      price: "₹ 990 - 1140",
    },
  },
  "dislodged prosthesis / filling": {
    color: "#401f37",
    gujarati: {
      name: "મોકૂફી આપેલ કૃત્રિમ અંગ",
      description: "ભરણ/ કૃત્રિમ અંગ કાં તો નુકસાન અથવા વિસ્થાપિત છે",
      treatment: "ઈશ્વરી",
      price: "₹ 350",
    },
    kannada: {
      name: "ಡಿಸ್ಲೊಡೆಡ್ ಪ್ರೊಸ್ಥೆಸಿಸ್",
      description: "ಫಿಲ್ಲಿಂಗ್/ಪ್ರೊಸ್ಥೆಸಿಸ್ ಹಾನಿಗೊಳಗಾಗಿದೆ ಅಥವಾ ಸ್ಥಳಾಂತರಗೊಂಡಿದೆ",
      treatment: "ಇ-ಫೈಲಿಂಗ್",
      price: "₹ 350",
    },
    english: {
      name: "Dislodged prosthesis",
      description: "The filling/ prosthesis is either damaged or displaced",
      treatment: "Re-filling",
      price: "₹ 350",
    },
    marathi: {
      name: "निघालेले फीलिन्ग/ कॅप",
      description: "फीलिंग/ कॅप/ ब्रिज खराब किंवा विस्थापित झाले आहेत",
      treatment: "रिफिलिंग",
      price: "₹ 350",
    },
    hindi: {
      name: "ढीली कैप/ फिलिंग",
      description: "विस्थापित कैप,ब्रिज या फिलिंग",
      treatment: "दुबारा भरिये",
      price: "₹ 350",
    },
    tamil: {
      name: "சிதைந்த செயற்கை உறுப்பு",
      description:
        "நிரப்புதல்/புரோஸ்டெசிஸ் சேதமடைந்துள்ளது அல்லது இடம்பெயர்ந்துள்ளது",
      treatment: "மின்-தாக்கல்",
      price: "₹ 350",
    },
    telugu: {
      name: "డిస్లోజ్డ్ ప్రొస్థెసిస్",
      description:
        "ఫిల్లింగ్/ప్రొస్థెసిస్ దెబ్బతింది లేదా స్థానభ్రంశం చెందుతుంది",
      treatment: "ఇ-ఫైలింగ్",
      price: "₹ 350",
    },
    ar: {
      name: "الأطراف الاصطناعية التي تم إزالتها",
      description: "التعبئة/ الأطراف الاصطناعية إما تالف أو نزح",
      treatment: "الإيداع الإلكتروني",
      price: "₹ 350",
    },
  },
  "inflammed / red gums": {
    color: "#661b40",
    gujarati: {
      name: "સોજો",
      description: "તકતી અને કેલ્ક્યુલસને કારણે લાલ અથવા સોજો પે ums ા",
      treatment: "ઉન્માદ",
      price: "₹ 150",
    },
    kannada: {
      name: "ಉರಿಯೂತದ ಗಮ್",
      description:
        "ಪ್ಲೇಕ್ ಮತ್ತು ಕಲನಶಾಸ್ತ್ರದ ಕಾರಣದಿಂದಾಗಿ ಕೆಂಪು ಅಥವಾ ಊದಿಕೊಂಡ ಒಸಡುಗಳು",
      treatment: "ಸ್ಕೇಲಿಂಗ್",
      price: "₹ 150",
    },
    english: {
      name: "Inflamed gum",
      description: "Red or swollen gums due to plaque & calculus",
      treatment: "Scaling",
      price: "₹ 150",
    },
    marathi: {
      name: "हिरड्यांना सूज",
      description: "सुजलेली आणि लाल झालेली हिरडी",
      treatment: "दात स्वच्छ करणे",
      price: "₹ 150",
    },
    hindi: {
      name: "सूजे मसूड़े",
      description: "लाल या सूजे हुए मसूड़े",
      treatment: "दांतो की सफाई",
      price: "₹ 150",
    },
    tamil: {
      name: "வீக்கமடைந்த ஈறு",
      description:
        "பிளேக் மற்றும் கால்குலஸ் காரணமாக சிவப்பு அல்லது வீங்கிய ஈறுகள்",
      treatment: "அளவிடுதல்",
      price: "₹ 150",
    },
    telugu: {
      name: "ఎర్రబడిన గమ్",
      description: "ఫలకం & కాలిక్యులస్ కారణంగా ఎరుపు లేదా వాపు చిగుళ్ళు",
      treatment: "స్కేలింగ్",
      price: "₹ 150",
    },
    ar: {
      name: "اللثة الملتهبة",
      description:
        "اللثة الحمراء أو المتورمة بسبب البلاك وحساب التفاضل والتكامل",
      treatment: "التحجيم",
      price: "₹ 150",
    },
  },
  "peg lateral": {
    color: "#DEB6AB",
    gujarati: {
      name: "પજવણી",
      description: "સામાન્ય ઉપલા આગળના દાંત કરતા નાના",
      treatment: "કોઇ",
      price: "₹ 920",
    },
    kannada: {
      name: "ಪೆಗ್ ಲ್ಯಾಟರಲ್",
      description: "ಸಾಮಾನ್ಯ ಮೇಲಿನ ಮುಂಭಾಗದ ಹಲ್ಲುಗಿಂತ ಚಿಕ್ಕದಾಗಿದೆ",
      treatment: "ಕ್ಯಾಪ್ / ವೆನಿರ್",
      price: "₹ 920",
    },
    english: {
      name: "Peg lateral",
      description: "Smaller than normal upper front tooth",
      treatment: "Cap/ Veneer",
      price: "₹ 920",
    },
    marathi: {
      name: "छोटे दात",
      description: "समोरील छोटे दात",
      treatment: "विनियर / कॅप",
      price: "₹ 920",
    },
    hindi: {
      name: "छोटा दाँत",
      description: "सामने के छोटे दाँत",
      treatment: "विनियर/ कैप",
      price: "₹ 920",
    },
    tamil: {
      name: "ஆப்பு பக்கவாட்டு",
      description: "சாதாரண மேல் முன் பல்லை விட சிறியது",
      treatment: "தொப்பி / வெனீர்",
      price: "₹ 920",
    },
    telugu: {
      name: "పెగ్ పార్శ్వ",
      description: "సాధారణ ఎగువ ముందు దంతాల కంటే చిన్నది",
      treatment: "టోపీ/ వెనీర్",
      price: "₹ 920",
    },
    ar: {
      name: "ربط جانبي",
      description: "أصغر من الأسنان الأمامية العلوية العادية",
      treatment: "غطاء/ قشرة",
      price: "₹ 920",
    },
  },
};
