import React from "react";

const IconButton = ({ buttonText, isLoading, onClick }) => {
  return (
    <>
      <button
        onClick={onClick}
        disabled={isLoading}
        class="inline-flex items-center justify-center h-12 gap-2 px-6 text-sm font-medium tracking-wide transition duration-300 rounded focus-visible:outline-none justify-self-center whitespace-nowrap bg-blue-900 text-white disabled:cursor-not-allowed disabled:border-blue-300 disabled:bg-blue-100 disabled:text-blue-400 disabled:shadow-none"
      >
        <span className="text-2xl">{buttonText}</span>
        <span class="relative only:-mx-6">
          {isLoading ? (
            <svg
              class="w-5 h-5 text-white animate-spin"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              role="graphics-symbol"
              aria-labelledby="title-05 desc-05"
            >
              <circle
                class="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                stroke-width="4"
              ></circle>
              <path
                class="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
          ) : (
            <svg
              width="25"
              height="20"
              viewBox="0 0 39 44"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.809625 0.319996H17.8016L38.7296 21.824L17.8016 43.328H0.809625L21.8336 21.824L0.809625 0.319996Z"
                fill="white"
              />
            </svg>
          )}
        </span>
      </button>
    </>
  );
};

export default IconButton;
