import Header from "../components/Header";
import clientLogo from "../assets/client-logo.png";
import guideVideo from "../assets/guide-video.mp4";
import Button from "../components/Button";
import Input from "../components/Input";
import { useSelector } from "react-redux";
import { getUserDetails } from "../store/rootReducer";
import { useNavigate } from "react-router-dom";

export default () => {
  const { name, mobile } = useSelector(getUserDetails);
  const navigate = useNavigate();
  const handleClick = () => {
    navigate("/onboarding-step2");
  };
  return (
    <div
      className="flex flex-col w-full justify-start text-center animate-myBounce"
      style={{
        height: "100vh",
      }}
    >
      {/* Shree Ganeshji */}
      <Header clientLogo={clientLogo} patientDetails={{ name, mobile }} />
      <br />
      <div className="text-6xl font-bold text-[#344277]">Step 1</div>
      <br />
      <div className="tracking-wide text-[#7C87AF]">
        Position yourself as per the guiding video
      </div>
      <br />
      <div
        className="flex flex-row w-full justify-center text-center"
        style={{ height: "65vh" }}
      >
        <div className="bg-black w-4/5 flex flex-col justify-evenly text-white">
          <video muted loop autoPlay>
            <source
              src={
                guideVideo
              }
              type="video/mp4"
            />
          </video>
        </div>
      </div>
      <br />
      <div className="text-[#344277] text-2xl">
        Are you in standing right in center of camera unit?
      </div>

      <br />
      <div className="flex flex-row justify-evenly">
        <Button buttonText={"Yes"} width={"w-1/5"} onClick={handleClick} />
      </div>
    </div>
  );
};
