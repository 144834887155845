import { useState } from "react";
import Keyboard from "react-simple-keyboard";
import keyboardClick from "../assets/audio/keyboard-click.wav";
export default ({ keyboard = "normal", onChange, inputReference }) => {
  const [isShift, setIsShift] = useState(false);
  const layouts = {
    normal: {
      //   shift: [
      //     "q w e r t y u i o p",
      //     "a s d f g h j k l",
      //     "{shift} z x c v b n m , .",
      //     "?123 {space} \u232B",
      //   ],
      default: [
        "Q W E R T Y U I O P",
        "A S D F G H J K L",
        "Z X C V B N M {bksp}",
        "{space} {enter}",
      ],
    },
    numbers: {
      default: ["1 2 3", "4 5 6", "7 8 9", "0 {bksp}"],
    },
  };
  const clickSound = new Audio(keyboardClick);
  const onKeyPress = (button) => {
    clickSound.play();
    if (["{lock}", "{shift}", "{shift}"].includes(button) || isShift) {
      setIsShift(!isShift);
    }
  };
  return (
    <Keyboard
      inputName={inputReference}
      layoutName={isShift ? "shift" : "default"}
      layout={layouts[keyboard]}
      onChange={onChange}
      theme={`hg-theme-default ${
        keyboard === "normal" ? "shree-ganesh" : "layout-number"
      }`}
      onKeyPress={onKeyPress}
      mergeDisplay={true}
      display={{
        ["{enter}"]: "Submit",
        ["{space}"]: "Space",
        ["{bksp}"]: "\u232B",
      }}
    />
  );
};
