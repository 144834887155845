import Header from "../components/Header";
import clientLogo from "../assets/client-logo.png";
// import Input from "../components/Input";
// import Keyboard from "../components/Keyboard";
import { useEffect, useState } from "react";
import IconButton from "../components/IconButton";
import ShowToast from "../components/ShowToast";
import { partnerLogin } from "../services/api.service";
import { useDispatch } from "react-redux";
import {
  setClinicId,
  setKioskHarwareConfig,
  setKioskId,
  setPartnerToken,
} from "../store/rootReducer";
import { useLocation, useNavigate } from "react-router-dom";
import Select from "../components/Select";

const KioskSelection = () => {
  const [formData, setFormData] = useState({
    username: "",
    password: "",
  });
  // const [selectedInput, setSelectedInput] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [clinic, setClinic] = useState("");
  const [clinics, setClinics] = useState([]);
  const [kiosk, setKiosk] = useState("");
  const [kiosks, setKiosks] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    setClinics(location?.state?.clinics);
    if (location?.state?.clinics?.length) {
      const { _id } = location?.state?.clinics[0];
      setClinic(_id);
    }
  }, []);

  useEffect(() => {
    if (clinic) {
      const selectedClinic = clinics.find(
        (clinicInner) => clinicInner._id === clinic
      );
      setKiosks(selectedClinic?.kiosk || []);
      if (selectedClinic?.kiosk?.length) {
        const { _id } = selectedClinic?.kiosk[0];
        setKiosk(_id);
      }
    }
  }, [clinic, clinics]);

  const isFormValid = () => {
    return clinic && kiosk;
  };

  const handleClick = async () => {
    const isValid = isFormValid();
    if (!isValid) {
      ShowToast.error("Invalid Input. Please provide correct values");
    } else {
      setIsLoading(true);
      try {
        dispatch(setKioskId(kiosk));
        dispatch(setClinicId(clinic));
        navigate("/home");
      } catch (error) {
        ShowToast.error("Something went wrong while login");
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleClinicChange = (event) => {
    setClinic(event.target.value);
  };

  const handleKioskChange = (event) => {
    setKiosk(event.target.value);
  };
  return (
    <div
      className="flex flex-col w-full justify-start text-center "
      style={{
        height: "100vh",
      }}
    >
      <Header
        clientLogo={clientLogo}
        // patientDetails={{ name: "Rajat Kabade", password: "+91-9812345678" }}
      />
      <br />
      <div className="text-6xl font-bold text-[#344277] flex flex-row justify-center">
        Hi There! &nbsp;
        <div className="text-6xl animate-[wiggle_1s_ease-in-out_infinite]">
          👋
        </div>
      </div>
      <br />
      <div className="tracking-wide text-[#7C87AF]">
        You are 3 steps away from magical AI dental scan
      </div>
      <br />
      <Select
        label={"Clinic"}
        onChange={handleClinicChange}
        options={clinics}
        selectedOption={clinic}
      />
      <Select
        label={"Device"}
        onChange={handleKioskChange}
        options={kiosks}
        selectedOption={kiosk}
      />
      <br />
      <div>
        <IconButton
          buttonText={"Submit"}
          isLoading={isLoading}
          onClick={handleClick}
        />
      </div>
    </div>
  );
};

export default KioskSelection;
