import adjustHeight from "../assets/audio/adjust_height.mp3";
import frontAudio from "../assets/audio/front.mp3";
import upperAudio from "../assets/audio/upper.mp3";
import lowerAudio from "../assets/audio/lower.mp3";
import holdTimer from "../assets/audio/hold_timer.wav";
import cameraClick from "../assets/audio/camera_click.wav";
import stayStill from "../assets/audio/stay_still.mp3";

class AudioGuideService {
  constructor() {
    this.sounds = {
      adjust_camera_height: new Audio(adjustHeight),
      FRONT: new Audio(frontAudio),
      UPPER: new Audio(upperAudio),
      LOWER: new Audio(lowerAudio),
      hold_timer: new Audio(holdTimer),
      camera_click: new Audio(cameraClick),
      stay_still: new Audio(stayStill),
    };
  }

  loadSounds(soundMapping) {
    for (const key in soundMapping) {
      this.sounds[key] = new Audio(process.env.PUBLIC_URL + soundMapping[key]);
    }
  }

  async stopAllSounds() {
    for (const key in this.sounds) {
      try {
        await this.stopSound(key);
      } catch (error) {
        console.log(error);
      }
    }
  }

  async playSound(key) {
    await this.stopAllSounds();
    if (this.sounds[key]) {
      await this.sounds[key].play();
    }
  }

  async stopSound(key) {
    if (this.sounds[key]) {
      await this.sounds[key].pause();
      this.sounds[key].currentTime = 0;
    }
  }

  registerSound(key, url) {
    this.sounds[key] = new Audio(url);
  }
}

export default new AudioGuideService();
