export const labelsMap = {
  kannada: {
    layout: "ltr",
    HOME_HEADING: "ನಿಮ್ಮ ಹಲ್ಲುಗಳನ್ನು ರಕ್ಷಿಸಲು ಸರಳ ಮಾರ್ಗ",
    HOME_SUBHEADING: "ಭಾಷೆಯನ್ನು ಆಯ್ಕೆಮಾಡಿ",
    LOGIN_HEADING: "ನಮಸ್ತೆ",
    LOGIN_SUBHEADING: "ಆರೋಗ್ಯಕರ ಬಾಯಿಯ ಕಡೆಗೆ ನಿಮ್ಮ ಪ್ರಯಾಣವನ್ನು ಪ್ರಾರಂಭಿಸೋಣ",
    LOGIN_INPUT_NAME: "ಹೆಸರು",
    LOGIN_INPUT_MOBILE: "ಮೊಬೈಲ್ ನಂಬರ",
    LOGIN_INPUT_OTP: "OTP",
    LOGIN_BUTTON_SEND_OTP: "ಕಳುಹಿಸು OTP",
    LOGIN_USER_BUTTON: "Login",
    LOGIN_INPUT_PASSWORD: "Password",
    LOGIN_INPUT_USERNAME: "Username",
    LOGIN_BUTTON_SUBMIT_OTP: "ಸಲ್ಲಿಸು OTP",
    LOGIN_BUTTON_RESEND_OTP: "OTP ಸಿಗಲಿಲ್ಲವೇ? ಮರುಕಳುಹಿಸಿ",
    LOGIN_BUTTON_EDIT_NUMBER: "ಸಂಖ್ಯೆ ಸಂಪಾದಿಸಿ",
    LOGIN_MESSAGE_OTP_SENT: "OTP ಕಳುಹಿಸಲಾಗಿದೆ",
    LOGIN_MESSAGE_INVALID_OTP: "ದಯವಿಟ್ಟು ಮಾನ್ಯವಾದ OTP ಅನ್ನು ಒದಗಿಸಿ",
    LOGIN_MESSAGE_OTP_ERROR: "OTP ಕಳುಹಿಸುವಾಗ ಏನೋ ತಪ್ಪಾಗಿದೆ",
    LOGIN_MESSAGE_OTP_SUBMIT_ERROR: "OTP ಸಲ್ಲಿಸುವಾಗ ಏನೋ ತಪ್ಪಾಗಿದೆ",
    LOGIN_MESSAGE_INVALID_DATA: "ದಯವಿಟ್ಟು ಮಾನ್ಯವಾದ ಡೇಟಾವನ್ನು ಭರ್ತಿ ಮಾಡಿ",
    SCAN_HEADING_TEETH: "ಹಲ್ಲುಗಳು",
    SCAN_INSTRUCTIONS_MAP: {
      front: [
        "ಮಾರ್ಗದರ್ಶಿ ವೀಡಿಯೊವನ್ನು ಎಚ್ಚರಿಕೆಯಿಂದ ವೀಕ್ಷಿಸಿ",
        "ಚೌಕಟ್ಟಿನಲ್ಲಿ ನಿಮ್ಮ ಮುಂಭಾಗದ ಕೋನದ ದವಡೆಯನ್ನು ಹೊಂದಿಸಿ",
        "ಕ್ಲಿಕ್ ಬಟನ್ ಆಯ್ಕೆಮಾಡಿ",
      ],
      upper: [
        "ಮಾರ್ಗದರ್ಶಿ ವೀಡಿಯೊವನ್ನು ಎಚ್ಚರಿಕೆಯಿಂದ ವೀಕ್ಷಿಸಿ",
        "ಚೌಕಟ್ಟಿನಲ್ಲಿ ನಿಮ್ಮ ಮೇಲಿನ ಕೋನದ ದವಡೆಯನ್ನು ಹೊಂದಿಸಿ",
        "ಕ್ಲಿಕ್ ಬಟನ್ ಆಯ್ಕೆಮಾಡಿ",
      ],
      lower: [
        "ಮಾರ್ಗದರ್ಶಿ ವೀಡಿಯೊವನ್ನು ಎಚ್ಚರಿಕೆಯಿಂದ ವೀಕ್ಷಿಸಿ",
        "ಚೌಕಟ್ಟಿನಲ್ಲಿ ನಿಮ್ಮ ಕೆಳ ಕೋನದ ದವಡೆಯನ್ನು ಹೊಂದಿಸಿ",
        "ಕ್ಲಿಕ್ ಬಟನ್ ಆಯ್ಕೆಮಾಡಿ",
      ],
    },
    SCAN_VIDEO_INSTRUCTIONS_MAP: {
      data: [
        "ಮಾರ್ಗದರ್ಶಿ ವೀಡಿಯೊವನ್ನು ಎಚ್ಚರಿಕೆಯಿಂದ ವೀಕ್ಷಿಸಿ",
        "ಸಾಧ್ಯವಾದಷ್ಟು ಹತ್ತಿರ ನಿಂತುಕೊಳ್ಳಿ. ವೀಡಿಯೊದಲ್ಲಿ ತೋರಿಸಿರುವಂತೆ ನಿಮ್ಮ ಹಲ್ಲುಗಳನ್ನು ತೋರಿಸಿ.",
        "ಎಲ್ಲಾ 3 ಕೋನಗಳನ್ನು ರೆಕಾರ್ಡ್ ಮಾಡಿದ ನಂತರ ವಿರಾಮ ಬಟನ್ ಕ್ಲಿಕ್ ಮಾಡಿ.",
      ],
    },
    SCAN_SUBMIT_BUTTON: "ಹಲ್ಲುಗಳನ್ನು ಸ್ಕ್ಯಾನ್ ಮಾಡಿ",
    ANGLE_LIST: ["ಮುಂಭಾಗ", "ಮೇಲ್ಭಾಗ", "ಕಡಿಮೆ"],
    VIDEO_MODE: "ವೀಡಿಯೊ ರೆಕಾರ್ಡ್ ಮಾಡಿ",
    REPORT_HEADING: "ನಿಮ್ಮ ದಂತ ವರದಿ",
    REPORT_SUB_SECTION_HEADING: "ನಿಮ್ಮ ಅಂದಾಜು ಚಿಕಿತ್ಸಾ ಆಯ್ಕೆಗಳು",
    NO_DETECTIONS_IN_ANGLE:
      "ಯಾವುದೇ ರೋಗಗಳು ಕಂಡುಬಂದಿಲ್ಲ. ಚಿತ್ರವು ಅಸಮರ್ಪಕವಾಗಿದ್ದರೆ, ದಯವಿಟ್ಟು ಮರುಪ್ರಯತ್ನಿಸಿ.",
    BOOK_APPOINTMENT_BUTTON: "ಪುಸ್ತಕ ನೇಮಕಾತಿ",
    DONWLOAD_REPORT_BUTTON: "ವರದಿಯನ್ನು ಡೌನ್‌ಲೋಡ್ ಮಾಡಿ",
    EXIT_REPORT_BUTTON: "ನಿರ್ಗಮಿಸಿ",
    APPOINTMENT_MESSAGE: "Call this number to book an appointment",
    APPOINTMENT_SECOND_SECTION_HEADING:
      "Fast Track & Evening Clinic at the School of Dental Sciences",
    APPOINTMENT_MORNING_SHIFT_TIMING: "Morning 9 am to Evening 5 pm",
    APPOINTMENT_EVENING_SHIFT_TIMING: "Evening 5 pm to 8.30 pm",
    DOWNLOAD_HEADING1:
      "ಸರಿಯಾದ ಮೌಖಿಕ ಆರೋಗ್ಯ ಅಭ್ಯಾಸಗಳು ಮತ್ತು ನೈರ್ಮಲ್ಯ ಅಭ್ಯಾಸಗಳನ್ನು ಅನುಸರಿಸಲು DentalDost ಅಪ್ಲಿಕೇಶನ್ ಬಳಸಿ",
    DOWNLOAD_HEADING2: "ಈ QR ಕೋಡ್ ಅನ್ನು ಸ್ಕ್ಯಾನ್ ಮಾಡಿ ಮತ್ತು ಈಗ ಡೌನ್‌ಲೋಡ್ ಮಾಡಿ",
    START_SCAN: "ಸ್ಕ್ಯಾನ್ ಪ್ರಾರಂಭಿಸಿ",
    CAMERA_ADJUSTING_TEXT:
      "ಕ್ಯಾಮರಾ ಸ್ವಯಂಚಾಲಿತವಾಗಿ ನಿಮ್ಮ ಎತ್ತರಕ್ಕೆ ಸರಿಹೊಂದಿಸುತ್ತದೆ",
    CLICK_ADJUST: "ಎತ್ತರವನ್ನು ಹೊಂದಿಸಿ",
    FRONT_TEETH: "ಮುಂಭಾಗದ ಹಲ್ಲುಗಳು",
    UPPER_TEETH: "ಮೇಲಿನ ಹಲ್ಲುಗಳು",
    LOWER_TEETH: "ಕೆಳಗಿನ ಹಲ್ಲುಗಳು",
    PLEASE_WAIT: "ದಯಮಾಡಿ ನಿರೀಕ್ಷಿಸಿ ...",
    KNOW_MORE: "ರೋಗಗಳ ಬಗ್ಗೆ ಇನ್ನಷ್ಟು ತಿಳಿಯಿರಿ",
    SEND_ON_WHATSAPP_BUTTON: "WhatsApp ನಲ್ಲಿ ಕಳುಹಿಸಿ",
    SEND_TO_DOCTOR_BUTTON: "ವೈದ್ಯರಿಗೆ ಕಳುಹಿಸಿ",
    CONTROL_DIALOUGE: {
      ENTER_PIN: "ಪಿನ್ ನಮೂದಿಸಿ",
      HOME: "ಮುಖಪುಟ (ರಿಫ್ರೆಶ್)",
      WIFI_SETTINGS: "ವೈಫೈ ಸೆಟ್ಟಿಂಗ್‌ಗಳು",
      TURN_OFF: "ಆಫ್ ಮಾಡಿ",
      RESTART: "ಮರುಪ್ರಾರಂಭಿಸಿ",
    },
    PROCEED_AGREE: "ಮುಂದುವರಿಯುವ ಮೂಲಕ, ನೀವು ಸ್ವೀಕರಿಸುತ್ತೀರಿ",
    TERMS_AND_CONDITIONS: "ನಿಯಮ ಮತ್ತು ಶರತ್ತುಗಳು.",
    EXPRESS_SCAN: "ಎಕ್ಸ್‌ಪ್ರೆಸ್ ಸ್ಕ್ಯಾನ್",
  },
  marathi: {
    layout: "ltr",
    HOME_HEADING: "तुमचे हसू सुरक्षित करण्याचा एक सोपा, स्मार्ट मार्ग",
    HOME_SUBHEADING: "भाषा निवडा",
    LOGIN_HEADING: "नमस्कार!",
    LOGIN_SUBHEADING: "चला निरोगी तोंडाकडे आपला प्रवास सुरू करूया.",
    LOGIN_INPUT_NAME: "नाव",
    LOGIN_INPUT_MOBILE: "मोबाईल नंबर",
    LOGIN_INPUT_OTP: "OTP",
    LOGIN_BUTTON_SEND_OTP: "OTP पाठवा",
    LOGIN_USER_BUTTON: "लॉगिन करा",
    LOGIN_INPUT_PASSWORD: "पासवर्ड",
    LOGIN_INPUT_USERNAME: "Username",
    LOGIN_BUTTON_SUBMIT_OTP: "OTP सबमिट करा",
    LOGIN_BUTTON_RESEND_OTP: "OTP मिळाला नाही? पुन्हा पाठवा",
    LOGIN_BUTTON_EDIT_NUMBER: "क्रमांक संपादित करा", //Edit Number
    LOGIN_MESSAGE_OTP_SENT: "OTP पाठवला", //OTP Sent
    LOGIN_MESSAGE_INVALID_OTP: "कृपया वैध OTP प्रदान करा",
    LOGIN_MESSAGE_OTP_ERROR: "OTP पाठवताना काहीतरी चूक झाली",
    LOGIN_MESSAGE_OTP_SUBMIT_ERROR: "OTP सबमिट करताना काहीतरी चूक झाली",
    LOGIN_MESSAGE_INVALID_DATA: "कृपया वैध डेटा भरा",
    SCAN_HEADING_TEETH: "दात",
    SCAN_INSTRUCTIONS_MAP: {
      front: [
        "मार्गदर्शक व्हिडिओ काळजीपूर्वक पहा",
        "फ्रेममध्ये तुमचा समोरचे दात फिट करा",
        "फोटो काढण्याचे बटण दाबा",
      ],
      upper: [
        "मार्गदर्शक व्हिडिओ काळजीपूर्वक पहा",
        "फ्रेममध्ये तुमचे वरचे दात फिट करा",
        "फोटो काढण्याचे बटण दाबा",
      ],
      lower: [
        "मार्गदर्शक व्हिडिओ काळजीपूर्वक पहा",
        "फ्रेममध्ये तुमचे खालचे दात फिट करा",
        "फोटो काढण्याचे बटण दाबा",
      ],
    },
    SCAN_VIDEO_INSTRUCTIONS_MAP: {
      data: [
        "मार्गदर्शक व्हिडिओ काळजीपूर्वक पहा",
        "कॅमेऱ्याच्या शक्य तितक्या जवळ उभे रहा. व्हिडिओमध्ये दाखवल्याप्रमाणे तुमचे दात दाखवा",
        "सर्व 3 अँगल रेकॉर्ड झाल्यानंतर पॉज बटण क्लिक करा.",
      ],
    },
    SCAN_SUBMIT_BUTTON: "दात स्कॅन करा",
    ANGLE_LIST: ["समोरचे", "वरचे", "खालचे"],
    VIDEO_MODE: "विडिओ रेकॉर्ड करा",
    REPORT_HEADING: "दातांचा रिपोर्ट",
    REPORT_SUB_SECTION_HEADING:
      "रिपोर्टमध्ये आढळलेल्या समस्या आणि अंदाजे उपचार पर्याय",
    NO_DETECTIONS_IN_ANGLE:
      "कोणतीही आजारांची शोध आढळलेली नाही, जर छायाचित्र अयोग्य असेल तर पुन्हा सबमिट करण्याचा प्रयत्न करा.",

    BOOK_APPOINTMENT_BUTTON: "अपॉइंटमेंट बुक करा",
    DONWLOAD_REPORT_BUTTON: "रिपोर्ट डाउनलोड करा",
    EXIT_REPORT_BUTTON: "बाहेर पडा",
    APPOINTMENT_MESSAGE: "अपॉइंटमेंट बुक करण्यासाठी या नंबरवर कॉल करा",
    APPOINTMENT_SECOND_SECTION_HEADING:
      "स्कूल ऑफ डेंटल सायन्सेस येथील फास्ट ट्रॅक व सायंकाळचे क्लिनिक",
    APPOINTMENT_MORNING_SHIFT_TIMING: "सकाळी ९ ते संध्याकाळी ५ पर्यंत",
    APPOINTMENT_EVENING_SHIFT_TIMING: "संध्याकाळी ५ ते ८.३० पर्यंत",
    DOWNLOAD_HEADING1:
      "ScanO ऍप द्वारे मिळावा तुमचा दंत आरोग्य रिपोर्ट आणि योग्य सवयींचे पालन करा",
    DOWNLOAD_HEADING2: "खालील QR कोड स्कॅन करा आणि आत्ताच डाउनलोड करा",
    START_SCAN: "स्कॅन सुरू करा",
    CAMERA_ADJUSTING_TEXT:
      " कॅमेरा आपल्या उंचावर स्वयंसिद्धपणे समायोजित होत आहे",
    CLICK_ADJUST: "उंची समायोजित करा",
    FRONT_TEETH: "मुखपूर्व दात",
    UPPER_TEETH: "वरील दात",
    LOWER_TEETH: "खालील दात",
    PLEASE_WAIT: "कृपया वाट पाहा ...",
    KNOW_MORE: "रोगांच्या बद्दल अधिक जाणून घ्या",
    SEND_ON_WHATSAPP_BUTTON: "व्हाट्सअँपवर पाठवा",
    SEND_TO_DOCTOR_BUTTON: "डॉक्टरकडून पाठवा",
    CONTROL_DIALOUGE: {
      ENTER_PIN: "पिन टाका",
      HOME: "मुख्यपृष्ठ (रिफ्रेश)",
      WIFI_SETTINGS: "वायफाय सेटिंग्ज",
      TURN_OFF: "बंद करा",
      RESTART: "रीस्टार्ट करा",
    },

    PROCEED_AGREE: "पुढे जाताना, आपल्याला स्वीकारायच्या आहेत ",
    TERMS_AND_CONDITIONS: "नियम व अटी।",
    EXPRESS_SCAN: "एक्सप्रेस स्कॅन",
  },
  hindi: {
    layout: "ltr",
    HOME_HEADING: "अपने दांतो की रक्षा करने का सब से आसान तरीका",
    HOME_SUBHEADING: "भाषा का चयन करें",
    LOGIN_HEADING: "नमस्ते!",
    LOGIN_SUBHEADING: "आइए एक स्वस्थ मुंह की ओर अपनी यात्रा शुरू करें।",
    LOGIN_INPUT_NAME: "नाम",
    LOGIN_INPUT_MOBILE: "मोबाइल नंबर",
    LOGIN_INPUT_OTP: "OTP",
    LOGIN_BUTTON_SEND_OTP: "OTP भेजें",
    LOGIN_USER_BUTTON: "लॉग इन करें",
    LOGIN_INPUT_PASSWORD: "पासवर्ड",
    LOGIN_INPUT_USERNAME: "उपयोगकर्ता नाम",
    LOGIN_BUTTON_SUBMIT_OTP: "OTP सबमिट करें",
    LOGIN_BUTTON_RESEND_OTP: "OTP नहीं मिला? पुन: भेजें",
    LOGIN_BUTTON_EDIT_NUMBER: "नंबर संपादित करें", //Edit Number
    LOGIN_MESSAGE_OTP_SENT: "OTP भेजा गया",
    LOGIN_MESSAGE_INVALID_OTP: "कृपया वैध OTP प्रदान करें",
    LOGIN_MESSAGE_OTP_ERROR: "OTP भेजते समय कुछ गलत हुआ",
    LOGIN_MESSAGE_OTP_SUBMIT_ERROR: "OTP सबमिट करते समय कुछ गड़बड़ी हुई",
    LOGIN_MESSAGE_INVALID_DATA: "कृपया मान्य डेटा भरें",
    SCAN_HEADING_TEETH: "दांत",
    SCAN_INSTRUCTIONS_MAP: {
      front: [
        "बगल वाला वीडियो को ध्यान से देखें",
        "अपने सामने के जबड़े को फ्रेम में फिट करें",
        "क्लिक बटन को दबाये",
      ],
      upper: [
        "बगल वाला वीडियो को ध्यान से देखें",
        "अपने उपर के के जबड़े को फ्रेम में फिट करें",
        "क्लिक बटन को दबाये",
      ],
      lower: [
        "बगल वाला वीडियो को ध्यान से देखें",
        "अपने नीचे के जबड़े को फ्रेम में फिट करें",
        "क्लिक बटन को दबाये",
      ],
    },
    SCAN_VIDEO_INSTRUCTIONS_MAP: {
      data: [
        "बगल वाला वीडियो को ध्यान से देखें",
        "कैमरे के जितना हो सके पास खड़े रहें। वीडियो में दिखाए अनुसार अपने दांत दिखाएं।",
        "एक बार सभी 3 ऐंगल को रिकॉर्ड करने के बाद पॉज बटन पर क्लिक करें।",
      ],
    },
    SCAN_SUBMIT_BUTTON: "दांतों को स्कैन करें",
    ANGLE_LIST: ["सामनेके", "उपरके", "नीचेके"],
    VIDEO_MODE: "वीडियो रिकॉर्ड करें",
    REPORT_HEADING: "दंत आरोग्य रिपोर्ट",
    REPORT_SUB_SECTION_HEADING: "आपका अनुमानित उपचार विकल्प",
    NO_DETECTIONS_IN_ANGLE:
      "कोई बीमारी नहीं पाई गई, अगर छवि ठीक नहीं है तो कृपया उसे फिर से सबमिट करें।",
    BOOK_APPOINTMENT_BUTTON: "अपॉइंटमेंट बुक करें",
    DONWLOAD_REPORT_BUTTON: "रिपोर्ट डाउनलोड करें",
    EXIT_REPORT_BUTTON: "बाहर पडें",
    APPOINTMENT_MESSAGE: "अपॉइंटमेंट बुक करने के लिए इस नंबर पर कॉल करें",
    APPOINTMENT_SECOND_SECTION_HEADING:
      "स्कूल ऑफ डेंटल साइंसेज का फास्ट ट्रैक और शाम का क्लिनिक",
    APPOINTMENT_MORNING_SHIFT_TIMING: "सुबह ९ से शाम को ५ तक",
    APPOINTMENT_EVENING_SHIFT_TIMING: "शाम को ५ से ८.३० बजे तक",
    DOWNLOAD_HEADING1:
      "ScanO ऐप में पाइए आपका दंत आरोग्य रिपोर्ट और सही मौखिक स्वास्थ्य आदतों का पालन करें",
    DOWNLOAD_HEADING2: "इस QR कोड को स्कैन करें और अभी डाउनलोड करें",
    START_SCAN: "स्कैन शुरू करें",
    CAMERA_ADJUSTING_TEXT:
      "कैमरा आपकी ऊंचाई के अनुसार स्वतः समायोजित हो रहा है",
    CLICK_ADJUST: "ऊंचाई समायोजित करें",
    FRONT_TEETH: "आगे के दांत",
    UPPER_TEETH: "ऊपरी दांत",
    LOWER_TEETH: "निचले दांत",
    PLEASE_WAIT: "कृपया प्रतीक्षा करें ...",
    KNOW_MORE: "रोगों के बारे में और जानें",
    SEND_ON_WHATSAPP_BUTTON: "व्हाट्सएप पर भेजें",
    SEND_TO_DOCTOR_BUTTON: "डॉक्टर को भेजें",
    CONTROL_DIALOUGE: {
      ENTER_PIN: "पिन दर्ज करें",
      HOME: "होम पेज (ताज़ा करें)",
      WIFI_SETTINGS: "वाईफ़ाई सेटिंग्स",
      TURN_OFF: "बंद करें",
      RESTART: "पुनः प्रारंभ करें",
    },
    PROCEED_AGREE: "आगे बढ़ते हुए, आप स्वीकार करते हैं ",
    TERMS_AND_CONDITIONS: "नियम और शर्तें।",
    EXPRESS_SCAN: "एक्सप्रेस स्कैन",
  },
  english: {
    layout: "ltr",
    HOME_HEADING: "oral health = overall health",
    HOME_SUBHEADING: "Choose your preferred language",
    LOGIN_HEADING: "Hi There!",
    LOGIN_SUBHEADING:
      "Let’s get your journey started towards a healthier mouth.",
    LOGIN_INPUT_NAME: "Name",
    LOGIN_INPUT_MOBILE: "Mobile Number",
    LOGIN_INPUT_OTP: "OTP",
    LOGIN_BUTTON_SEND_OTP: "Send OTP",
    LOGIN_USER_BUTTON: "Login",
    LOGIN_INPUT_PASSWORD: "Password",
    LOGIN_INPUT_USERNAME: "Username",
    LOGIN_BUTTON_SUBMIT_OTP: "Submit OTP",
    LOGIN_BUTTON_RESEND_OTP: "Didn’t get OTP? Resend",
    LOGIN_BUTTON_EDIT_NUMBER: "Edit Number",
    LOGIN_MESSAGE_OTP_SENT: "OTP Sent",
    LOGIN_MESSAGE_INVALID_OTP: "Please provide valid OTP",
    LOGIN_MESSAGE_OTP_ERROR: "Something went wrong while sending OTP",
    LOGIN_MESSAGE_OTP_SUBMIT_ERROR: "Something went wrong while submitting OTP",
    LOGIN_MESSAGE_INVALID_DATA: "Please fill valid data",
    SCAN_HEADING_TEETH: "Teeth",
    SCAN_INSTRUCTIONS_MAP: {
      front: [
        "Watch the guiding video carefully",
        "Fit your front angle jaw in the frame",
        "Select the click button",
      ],
      upper: [
        "Watch the guiding video carefully",
        "Fit your upper angle jaw in the frame",
        "Select the click button",
      ],
      lower: [
        "Watch the guiding video carefully",
        "Fit your lower angle jaw in the frame",
        "Select the click button",
      ],
    },
    SCAN_VIDEO_INSTRUCTIONS_MAP: {
      data: [
        "Watch the reference videos carefully",
        "Follow the audio instructions",
        "Wait for camera to capture your jaw image",
      ],
    },
    SCAN_SUBMIT_BUTTON: "Full Scan",
    ANGLE_LIST: ["Front", "Upper", "Lower"],
    VIDEO_MODE: "Record Video",
    REPORT_HEADING: "SCREENING RESULT",
    REPORT_SUB_SECTION_HEADING: "PRELIMINARY FINDINGS",
    NO_DETECTIONS_IN_ANGLE:
      "No diseases found. If the image is improper, please retry.",
    BOOK_APPOINTMENT_BUTTON: "Book Appointment",
    DONWLOAD_REPORT_BUTTON: "Download Report",
    EXIT_REPORT_BUTTON: "EXIT",
    APPOINTMENT_MESSAGE: "Call this number to book an appointment",
    APPOINTMENT_SECOND_SECTION_HEADING:
      "Fast Track & Evening Clinic at the School of Dental Sciences",
    APPOINTMENT_MORNING_SHIFT_TIMING: "Morning 9 am to Evening 5 pm",
    APPOINTMENT_EVENING_SHIFT_TIMING: "Evening 5 pm to 8.30 pm",
    DOWNLOAD_HEADING1:
      "Use ScanO app to follow right oral health habits and hygiene practices",
    DOWNLOAD_HEADING2: "Scan this QR code and Download Now",
    START_SCAN: "START SCAN",
    CAMERA_ADJUSTING_TEXT: "camera is automatically detecting your height",
    CLICK_ADJUST: "adjust height",
    FRONT_TEETH: "FRONT TEETH",
    UPPER_TEETH: "UPPER TEETH",
    LOWER_TEETH: "LOWER TEETH",
    PLEASE_WAIT: "Please wait...",
    KNOW_MORE: "Know more about the diseases",
    SEND_ON_WHATSAPP_BUTTON: "Send on WhatsApp",
    SEND_TO_DOCTOR_BUTTON: "Send to Doctor",
    CONTROL_DIALOUGE: {
      ENTER_PIN: "ENTER PIN",
      HOME: "Home (Refresh)",
      WIFI_SETTINGS: "WiFi Settings",
      TURN_OFF: "Turn Off",
      RESTART: "Restart",
    },
    PROCEED_AGREE: "By proceeding, you accept the ",
    TERMS_AND_CONDITIONS: "Terms & Conditions.",
    EXPRESS_SCAN: "Express Scan",
  },
  tamil: {
    layout: "ltr",
    HOME_HEADING: "உங்கள் பற்களை பாதுகாக்க எளிய வழி",
    HOME_SUBHEADING: "மொழியைத் தேர்ந்தெடுக்கவும்",
    LOGIN_HEADING: "வணக்கம்!",
    LOGIN_SUBHEADING: "ஆரோக்கியமான வாயை நோக்கி உங்கள் பயணத்தைத் தொடங்குவோம்.",
    LOGIN_INPUT_NAME: "பெயர்",
    LOGIN_INPUT_MOBILE: "Mobile Number",
    LOGIN_INPUT_OTP: "OTP",
    LOGIN_BUTTON_SEND_OTP: "அனுப்பு OTP",
    LOGIN_USER_BUTTON: "உள்நுழைய",
    LOGIN_INPUT_PASSWORD: "கடவுச்சொல்",
    LOGIN_INPUT_USERNAME: "பயனர் பெயர்",
    LOGIN_BUTTON_SUBMIT_OTP: "Submit OTP",
    LOGIN_BUTTON_RESEND_OTP: "Didn’t get OTP? Resend",
    LOGIN_BUTTON_EDIT_NUMBER: "Edit Number",
    LOGIN_MESSAGE_OTP_SENT: "OTP Sent",
    LOGIN_MESSAGE_INVALID_OTP: "Please provide valid OTP",
    LOGIN_MESSAGE_OTP_ERROR: "அனுப்பும்போது ஏதோ தவறு ஏற்பட்டது OTP",
    LOGIN_MESSAGE_OTP_SUBMIT_ERROR:
      "சமர்ப்பிக்கும் போது ஏதோ தவறு ஏற்பட்டது OTP",
    LOGIN_MESSAGE_INVALID_DATA: "சரியான தரவை நிரப்பவும்",
    SCAN_HEADING_TEETH: "பற்கள்",
    SCAN_INSTRUCTIONS_MAP: {
      front: [
        "வழிகாட்டி வீடியோவை கவனமாக பாருங்கள்",
        "சட்டத்தில் உங்கள் முன் கோண தாடை பொருத்தவும்",
        "கிளிக் பொத்தானைத் தேர்ந்தெடுக்கவும்",
      ],
      upper: [
        "வழிகாட்டி வீடியோவை கவனமாக பாருங்கள்",
        "சட்டத்தில் உங்கள் மேல் கோண தாடை பொருத்தவும்",
        "Select the click button",
      ],
      lower: [
        "வழிகாட்டி வீடியோவை கவனமாக பாருங்கள்",
        "உங்கள் கீழ் கோண தாடையை சட்டகத்தில் பொருத்தவும்",
        "கிளிக் பொத்தானைத் தேர்ந்தெடுக்கவும்",
      ],
    },
    SCAN_VIDEO_INSTRUCTIONS_MAP: {
      data: [
        "வழிகாட்டும் வீடியோவை கவனமாகப் பாருங்கள்",
        "முடிந்தவரை நெருக்கமாக நிற்கவும். வீடியோவில் காட்டப்பட்டுள்ளபடி உங்கள் பற்களைக் காட்டுங்கள்.",
        "அனைத்து 3 கோணங்களும் பதிவு செய்யப்பட்டவுடன் இடைநிறுத்த பொத்தானைக் கிளிக் செய்யவும்.",
      ],
    },
    SCAN_SUBMIT_BUTTON: "பல் ஸ்கேன்",
    ANGLE_LIST: ["முகம்", "மேல்", "கீழ்"],
    VIDEO_MODE: "வீடியோ பதிவு",
    REPORT_HEADING: "உங்கள் பல அறிகுறி",
    REPORT_SUB_SECTION_HEADING:
      "உங்கள் மருத்துவ விருப்பங்கள் மதிப்பிடப்பட்டுள்ளன",
    NO_DETECTIONS_IN_ANGLE:
      "வியாதிகள் காணப்படவில்லை. படம் தவறானால், மீண்டும் முயற்சி செய்யவும்.",
    BOOK_APPOINTMENT_BUTTON: "முகாம் பதிவு",
    DONWLOAD_REPORT_BUTTON: "அறிகுறி பதிவிறக்கம்",
    EXIT_REPORT_BUTTON: "வெளியேறு",
    APPOINTMENT_MESSAGE: "முகாம் பதிவுக்கு இந்த எண்ணை அழைக்கவும்",
    APPOINTMENT_SECOND_SECTION_HEADING:
      "பல அறிவியல் பள்ளியில் வேக ட்ராக் & மாலை கிளினிக்",
    APPOINTMENT_MORNING_SHIFT_TIMING: "காலை 9 மணி முதல் மாலை 5 மணி வரை",
    APPOINTMENT_EVENING_SHIFT_TIMING: "மாலை 5 மணி முதல் 8.30 மணி வரை",
    DOWNLOAD_HEADING1:
      "சரியான வாய்வழி சுகாதார பழக்கம் மற்றும் சுகாதார நடைமுறைகளைப் பின்பற்ற ScanO பயன்பாட்டைப் பயன்படுத்தவும்",
    DOWNLOAD_HEADING2: "இந்த QR குறியீட்டை ஸ்கேன் செய்து இப்போது பதிவிறக்கவும்",
    START_SCAN: "ஸ்கேன் ஆரம்பிக்கவும்",
    CAMERA_ADJUSTING_TEXT: "கேமரா உங்கள் உயரத்துக்கு தானாக செம்மைபடுகிறது",
    CLICK_ADJUST: "உயரம் செய்",
    FRONT_TEETH: "முக பல்",
    UPPER_TEETH: "மேல் பல்",
    LOWER_TEETH: "கீழ் பல்",
    PLEASE_WAIT: "காத்திருக்கவும் ...",
    KNOW_MORE: "பிரச்சினைகள் பற்றிய மேலும் அறிந்து கொள்ளுங்கள்",
    SEND_ON_WHATSAPP_BUTTON: "வாட்ஸாப்பில் அனுப்பு",
    SEND_TO_DOCTOR_BUTTON: "டாக்டருக்கு அனுப்பு",
    CONTROL_DIALOUGE: {
      ENTER_PIN: "பின்னை உள்ளிடவும்",
      HOME: "முகப்பு பக்கம்(புதுப்பித்தல்)",
      WIFI_SETTINGS: "வைஃபை அமைப்புகள்",
      TURN_OFF: "அணைக்கவும்",
      RESTART: "மறுதொடக்கம்",
    },
    PROCEED_AGREE: "முன்னேறிவதன் மூலம், நீங்கள் ஏற்றுக்கொள்கின்றீர்கள் ",
    TERMS_AND_CONDITIONS: "விதிமுறைகள் & நிபந்தனைகள்.",
    EXPRESS_SCAN: "எக்ஸ்பிரஸ் ஸ்கேன்",
  },
  telugu: {
    layout: "ltr",
    HOME_HEADING: "మీ దంతాలను సురక్షితంగా ఉంచే అత్యంత సరళ మార్గం",
    HOME_SUBHEADING: "భాషను ఎంచుకోండి",
    LOGIN_HEADING: "హలో! ఇక్కడ రాండి",
    LOGIN_SUBHEADING: "మీ దంత ఆరోగ్యంపై ప్రయాణం ప్రారంభించండి",
    LOGIN_INPUT_NAME: "పేరు",
    LOGIN_INPUT_MOBILE: "మొబైల్ సంఖ్య",
    LOGIN_INPUT_OTP: "OTP",
    LOGIN_BUTTON_SEND_OTP: "OTP పంపు",
    LOGIN_USER_BUTTON: "లాగిన్",
    LOGIN_INPUT_PASSWORD: "పాస్వర్డ్",
    LOGIN_INPUT_USERNAME: "వాడుకరి పేరు",
    LOGIN_BUTTON_SUBMIT_OTP: "OTP సమర్పించు",
    LOGIN_BUTTON_RESEND_OTP: "OTP రాలేదు? మళ్ళీ పంపు",
    LOGIN_BUTTON_EDIT_NUMBER: "సంఖ్యను మార్చు",
    LOGIN_MESSAGE_OTP_SENT: "OTP పంపబడింది",
    LOGIN_MESSAGE_INVALID_OTP: "తప్పు OTP, దయచేసి సరిగ్గా నమోదు చేయండి",
    LOGIN_MESSAGE_OTP_ERROR: "OTP పంపడంలో లోపం ఉంది",
    LOGIN_MESSAGE_OTP_SUBMIT_ERROR: "OTP సమర్పణలో లోపం ఉంది",
    LOGIN_MESSAGE_INVALID_DATA: "తప్పుడు డేటా, దయచేసి సరిచేయండి",
    SCAN_HEADING_TEETH: "దంతాలు",
    SCAN_INSTRUCTIONS_MAP: {
      front: [
        "మార్గదర్శక వీడియోను జాగ్రత్తగా చూడండి",
        "ముందునుంచి మీ దంతాలను ఫ్రేములో పెట్టండి",
        "క్లిక్ బటన్ నొక్కండి",
      ],
      upper: [
        "మార్గదర్శక వీడియోను కనపడుటకు సావధానంగా చూడండి",
        "మీ పైన కోణ చెక్ లో మీ పైన కోన జాబితలో సరైనట్టున్నది",
        "క్లిక్ బటన్‌ను ఎంచుకోండి",
      ],
      lower: [
        "మార్గదర్శక వీడియోను కనపడుటకు సావధానంగా చూడండి",
        "మీ క్రింద కోణ చెక్ లో మీ క్రింద కోన జాబితలో సరైనట్టున్నది",
        "క్లిక్ బటన్‌ను ఎంచుకోండి",
      ],
    },
    SCAN_VIDEO_INSTRUCTIONS_MAP: {
      data: [
        "మార్గదర్శక వీడియోను కనపడుటకు సావధానంగా చూడండి",
        "అత్యంత సమీపంగా నిండండి. వీడియోలో చూపించినట్టున్నట్టున్న రెకార్డింగ్ మూడు కోణాలు రికార్డ్ అయ్యేలా చేయండి.",
      ],
    },
    SCAN_SUBMIT_BUTTON: "దంతాలను స్కాన్ చేయండి",
    ANGLE_LIST: ["ముందు", "పైన", "క్రింద"],
    VIDEO_MODE: "వీడియో రికార్డ్",
    REPORT_HEADING: "మీ డెంటల్ నివేదిక",
    REPORT_SUB_SECTION_HEADING: "మీ అంచనా చేసే అంశాల అంశాలను",
    NO_DETECTIONS_IN_ANGLE:
      "ఏ రోగాలు కనపడలేదు. ఇమేజ్ తప్పుగా ఉంటే, దయచేసి మళ్ళీ ప్రయత్నించండి.",
    BOOK_APPOINTMENT_BUTTON: "అపాయింట్మెంట్ బుక్ చేయండి",
    DONWLOAD_REPORT_BUTTON: "నివేదికను డౌన్లోడ్ చేయండి",
    EXIT_REPORT_BUTTON: "నివేదికను బయటకు వెళ్ళండి",
    APPOINTMENT_MESSAGE: "ఈ నంబర్‌ను అపాయింట్మెంట్ బుక్ చేయడానికి కాల్ చేయండి",
    APPOINTMENT_SECOND_SECTION_HEADING:
      "డెంటల్ సైన్సెస్ స్కూల్లో ఫాస్ట్ ట్రాక్ & ఈవెనింగ్ క్లినిక్",
    APPOINTMENT_MORNING_SHIFT_TIMING: "మరింగ్ 9 గంటల నుంచి ఈవెనింగ్ 5 గంటలకు",
    APPOINTMENT_EVENING_SHIFT_TIMING: "ఈవెనింగ్ 5 గంటల నుంచి 8.30 గంటలకు",
    DOWNLOAD_HEADING1:
      "ScanO యాప్‌ను ఉపయోగించి సరైన ఓరల్ ఆరోగ్య అలవాట్లు మరియు శుచిత్వ ప్రాక్టీసులను అనుసరించండి",
    DOWNLOAD_HEADING2: "ఈ క్యూఆర్ కోడ్‌ను స్కాన్ చేసి ఇప్పుడు డౌన్‌లోడ్ చేయండి",
    START_SCAN: "స్కాన్ ప్రారంభించండి",
    CAMERA_ADJUSTING_TEXT: "కెమెరా మీ ఎత్తుకి ఆటోమెటిక్‌గా అడజస్ట్ అవుతోంది",
    CLICK_ADJUST: "ఎత్తు సరిచేయండి",
    FRONT_TEETH: "ముందు పల్లు",
    UPPER_TEETH: "పైన పల్లు",
    LOWER_TEETH: "కింద పల్లు",
    PLEASE_WAIT: "దయచేసి వేచి ఉండండి ...",
    KNOW_MORE: "వ్యాధుల గురించి మరింత తెలుసుకోండి",
    SEND_ON_WHATSAPP_BUTTON: "వాట్సాప్‌పై పంపండి",
    SEND_TO_DOCTOR_BUTTON: "డాక్టర్‌కి పంపండి",
    CONTROL_DIALOUGE: {
      ENTER_PIN: "పిన్ నమోదు చేయండి",
      HOME: "హోమ్ పేజీ(రిఫ్రెష్)",
      WIFI_SETTINGS: "WiFi సెట్టింగ్‌లు",
      TURN_OFF: "ఆఫ్ చేయండి",
      RESTART: "పునఃప్రారంభించండి",
    },
    PROCEED_AGREE: "ముందుకెళ్లడం ద్వారా, మీరు అంగీకరిస్తారు ",
    TERMS_AND_CONDITIONS: "నిబంధనలు & షరతులు.",
    EXPRESS_SCAN: "ఎక్స్‌ప్రెస్ స్కాన్",
  },
  ar: {
    layout: "rtl",
    HOME_HEADING: "أبسط طريقة لحماية أسنانك",
    HOME_SUBHEADING: "اختر اللغة",
    LOGIN_HEADING: "مرحبًا!",
    LOGIN_SUBHEADING: "لنبدأ رحلتك نحو فم أكثر صحة.",
    LOGIN_INPUT_NAME: "الاسم",
    LOGIN_INPUT_MOBILE: "رقم الهاتف المحمول",
    LOGIN_INPUT_OTP: "رمز مرور مؤقت",
    LOGIN_BUTTON_SEND_OTP: "إرسال رمز مرور مؤقت",
    LOGIN_USER_BUTTON: "تسجيل الدخول",
    LOGIN_INPUT_PASSWORD: "كلمة المرور",
    LOGIN_INPUT_USERNAME: "اسم المستخدم",
    LOGIN_BUTTON_SUBMIT_OTP: "تقديم رمز مرور مؤقت",
    LOGIN_BUTTON_RESEND_OTP: "لم تحصل على رمز مرور مؤقت؟ أعد الإرسال",
    LOGIN_BUTTON_EDIT_NUMBER: "تعديل الرقم",
    LOGIN_MESSAGE_OTP_SENT: "تم إرسال رمز المرور المؤقت",
    LOGIN_MESSAGE_INVALID_OTP: "يرجى تقديم رمز مرور مؤقت صحيح",
    LOGIN_MESSAGE_OTP_ERROR: "حدث خطأ أثناء إرسال رمز المرور المؤقت",
    LOGIN_MESSAGE_OTP_SUBMIT_ERROR: "حدث خطأ أثناء تقديم رمز المرور المؤقت",
    LOGIN_MESSAGE_INVALID_DATA: "يرجى ملء البيانات بشكل صحيح",
    SCAN_HEADING_TEETH: "الأسنان",
    SCAN_INSTRUCTIONS_MAP: {
      front: [
        "شاهد الفيديو التوجيهي بعناية",
        "ضع فكك الأمامي في الإطار",
        "اختر زر النقر",
      ],
      upper: [
        "شاهد الفيديو التوجيهي بعناية",
        "ضع فكك العلوي في الإطار",
        "اختر زر النقر",
      ],
      lower: [
        "شاهد الفيديو التوجيهي بعناية",
        "ضع فكك السفلي في الإطار",
        "اختر زر النقر",
      ],
    },
    SCAN_VIDEO_INSTRUCTIONS_MAP: {
      data: [
        "شاهد مقاطع الفيديو المرجعية بعناية",
        "استمع واتبع التعليمات الصوتية",
        "انتظر حتى تلتقط الكاميرا صورة الفك",
      ],
    },
    SCAN_SUBMIT_BUTTON: "مسح كامل",
    ANGLE_LIST: ["أمامي", "علوي", "سفلي"],
    VIDEO_MODE: "تسجيل فيديو",
    REPORT_HEADING: "تقرير الأسنان الخاص بك",
    REPORT_SUB_SECTION_HEADING: "خيارات العلاج المقدرة لك",
    NO_DETECTIONS_IN_ANGLE:
      "لم يتم العثور على أمراض. إذا كانت الصورة غير صحيحة، يرجى إعادة المحاولة.",
    BOOK_APPOINTMENT_BUTTON: "حجز موعد",
    DONWLOAD_REPORT_BUTTON: "تحميل التقرير",
    EXIT_REPORT_BUTTON: "خروج",
    APPOINTMENT_MESSAGE: "اتصل بهذا الرقم لحجز موعد",
    APPOINTMENT_SECOND_SECTION_HEADING:
      "عيادة السرعة والمساء في كلية علوم الأسنان",
    APPOINTMENT_MORNING_SHIFT_TIMING: "الصباح 9 صباحًا حتى المساء 5 مساءً",
    APPOINTMENT_EVENING_SHIFT_TIMING: "المساء 5 مساءً حتى 8.30 مساءً",
    DOWNLOAD_HEADING1:
      "استخدم تطبيق ScanO لاتباع عادات صحة الفم الصحيحة وممارسات النظافة",
    DOWNLOAD_HEADING2: "امسح هذا الرمز الشريطي وقم بالتنزيل الآن",
    START_SCAN: "بدء المسح",
    CAMERA_ADJUSTING_TEXT: "تكتشف الكاميرا طولك تلقائيًا",
    CLICK_ADJUST: "ضبط الارتفاع",
    FRONT_TEETH: "الأسنان الأمامية",
    UPPER_TEETH: "الأسنان العلوية",
    LOWER_TEETH: "الأسنان السفلية",
    PLEASE_WAIT: "...انتظر من فضلك",
    KNOW_MORE: "تعرف أكثر على الأمراض",
    SEND_ON_WHATSAPP_BUTTON: "أرسل عبر واتساب",
    SEND_TO_DOCTOR_BUTTON: "أرسل إلى الطبيب",
    CONTROL_DIALOUGE: {
      ENTER_PIN: "أدخل رقم التعريف الشخصي",
      HOME: "الصفحة الرئيسية",
      WIFI_SETTINGS: "اعدادات الواي فاي",
      TURN_OFF: "أطفأ",
      RESTART: "إعادة تشغيل",
    },
    PROCEED_AGREE: "بالاستمرار، أنت تقبل ",
    TERMS_AND_CONDITIONS: "الأحكام والشروط.",
    EXPRESS_SCAN: "اكسبريس سكان",
  },
  gujarati: {
    layout: "ltr",
    HOME_HEADING: "તમારા દાંતોને સુરક્ષિત રાખવાનો સૌથી સરળ ઉપાય",
    HOME_SUBHEADING: "તમારી પસંદગીની ભાષા પસંદ કરો",
    LOGIN_HEADING: "હાય ત્યાં!",
    LOGIN_SUBHEADING: "આવો તમારી તંદુરસ્ત મોઢાની તરફની યાત્રા શરૂ કરીએ.",
    LOGIN_INPUT_NAME: "નામ",
    LOGIN_INPUT_MOBILE: "મોબાઇલ નંબર",
    LOGIN_INPUT_OTP: "OTP",
    LOGIN_BUTTON_SEND_OTP: "OTP મોકલો",
    LOGIN_USER_BUTTON: "લૉગિન",
    LOGIN_INPUT_PASSWORD: "પાસવર્ડ",
    LOGIN_INPUT_USERNAME: "યુઝરનેમ",
    LOGIN_BUTTON_SUBMIT_OTP: "OTP સબમિટ કરો",
    LOGIN_BUTTON_RESEND_OTP: "OTP મળ્યો નથી? ફરી મોકલો",
    LOGIN_BUTTON_EDIT_NUMBER: "નંબર સુધારો",
    LOGIN_MESSAGE_OTP_SENT: "OTP મોકલાયો",
    LOGIN_MESSAGE_INVALID_OTP: "કૃપા કરી માન્ય OTP પૂરો પાડો",
    LOGIN_MESSAGE_OTP_ERROR: "OTP મોકલવામાં કંઇક ખોટું થયું",
    LOGIN_MESSAGE_OTP_SUBMIT_ERROR: "OTP સબમિટ કરવામાં કંઇક ખોટું થયું",
    LOGIN_MESSAGE_INVALID_DATA: "કૃપા કરી માન્ય ડેટા ભરો",
    SCAN_HEADING_TEETH: "દાંત",
    SCAN_INSTRUCTIONS_MAP: {
      front: [
        "સૂચના વિડીઓ ધ્યાનથી જુઓ",
        "તમારું ફ્રન્ટ એંગલ જડબું ફ્રેમમાં ફિટ કરો",
        "ક્લિક બટન પસંદ કરો",
      ],
      upper: [
        "સૂચના વિડીઓ ધ્યાનથી જુઓ",
        "તમારું ઉપરનું એંગલ જડબું ફ્રેમમાં ફિટ કરો",
        "ક્લિક બટન પસંદ કરો",
      ],
      lower: [
        "સૂચના વિડીઓ ધ્યાનથી જુઓ",
        "તમારું નીચેનું એંગલ જડબું ફ્રેમમાં ફિટ કરો",
        "ક્લિક બટન પસંદ કરો",
      ],
    },
    SCAN_VIDEO_INSTRUCTIONS_MAP: {
      data: [
        "સૂચના વિડીઓ ધ્યાનથી જુઓ",
        "જેટલું શક્ય હોય તેટલું નજીક ઉભા રહો. વિડીઓમાં દર્શાવેલ મુજબ તમારા દાંતો બતાવો.",
        "એકવાર ત્રણેય એંગલ્સ રેકોર્ડ થઇ જાય ત્યારે પૉઝ બટન દબાવો.",
      ],
    },
    SCAN_SUBMIT_BUTTON: "દાંત સ્કેન કરો",
    ANGLE_LIST: ["ફ્રન્ટ", "ઉપર", "નીચે"],
    VIDEO_MODE: "વિડીઓ રેકોર્ડ કરો",
    REPORT_HEADING: "તમારો દાંતનો રિપોર્ટ",
    REPORT_SUB_SECTION_HEADING: "તમારા અંદાજીત સારવાર વિકલ્પો",
    NO_DETECTIONS_IN_ANGLE:
      "કોઈ રોગો શોધાયા નથી. જો છબી અયોગ્ય હોય તો, કૃપા કરી ફરી પ્રયાસ કરો.",
    BOOK_APPOINTMENT_BUTTON: "એપોઈન્ટમેન્ટ બુક કરો",
    DONWLOAD_REPORT_BUTTON: "રિપોર્ટ ડાઉનલોડ કરો",
    EXIT_REPORT_BUTTON: "બહાર નીકળો",
    APPOINTMENT_MESSAGE: "અપોઈન્ટમેન્ટ બુક કરવા માટે આ નંબર પર કૉલ કરો",
    APPOINTMENT_SECOND_SECTION_HEADING:
      "ડેન્ટલ સાયન્સ સ્કૂલમાં ફાસ્ટ ટ્રેક અને સાંજની ક્લિનિક",
    APPOINTMENT_MORNING_SHIFT_TIMING: "સવારના 9 થી સાંજના 5 સુધી",
    APPOINTMENT_EVENING_SHIFT_TIMING: "સાંજના 5 થી 8.30 સુધી",
    DOWNLOAD_HEADING1:
      "યોગ્ય મૌખિક સ્વાસ્થ્ય ટેવો અને સ્વચ્છતા પ્રથાઓ અનુસરવા માટે DentalDost એપ વાપરો",
    DOWNLOAD_HEADING2: "આ QR કોડ સ્કેન કરો અને હવે ડાઉનલોડ કરો",
    START_SCAN: "સ્કેન શરૂ કરો",
    CAMERA_ADJUSTING_TEXT: "કેમેરો આપોઆપ તમારી ઊંચાઈ સાથે ગોઠવાઈ રહ્યો છે",
    CLICK_ADJUST: "ઊંચાઈ ગોઠવો",
    FRONT_TEETH: "સામેના દાંત",
    UPPER_TEETH: "ઉપરના દાંત",
    LOWER_TEETH: "નીચેના દાંત",
    PLEASE_WAIT: "કૃપયા રાહ જુઓ ...",
    KNOW_MORE: "રોગો વિશે વધુ જાણો",
    SEND_ON_WHATSAPP_BUTTON: "WhatsApp પર મોકલો",
    SEND_TO_DOCTOR_BUTTON: "ડોક્ટરને મોકલો",
    CONTROL_DIALOUGE: {
      ENTER_PIN: "પિન દાખલ કરો",
      HOME: "હોમ પેજ(તાજું કરો)",
      WIFI_SETTINGS: "WiFi સેટિંગ્સ",
      TURN_OFF: "બંધ કરો",
      RESTART: "પુનઃપ્રારંભ",
    },
    PROCEED_AGREE: "આગળ વધવાથી, તમે સ્વીકારો છો",
    TERMS_AND_CONDITIONS: "શરતો અને નિયમો.",
    EXPRESS_SCAN: "એક્સપ્રેસ સ્કેન",
  },
};
