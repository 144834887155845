import Header from "../components/Header";
import clientLogo from "../assets/client-logo.png";
import Button from "../components/Button";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  changeLanguage,
  getKioskId,
  getLanguageList,
  getPartnerToken,
} from "../store/rootReducer";
import React, { useEffect, useState } from "react";
import IconButton from "../components/IconButton";
import { healthcheck } from "../services/api.service";
import ShowToast from "../components/ShowToast";
import socketService from "../services/socket.service";

export default () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const languageList = useSelector(getLanguageList);
  const kioskId = useSelector(getKioskId);
  const partnerToken = useSelector(getPartnerToken);
  const [isLoading, setIsLoading] = useState({});
  useEffect(() => {
    socketService.reset();
  }, []);
  const finalLangueages = [
    // {
    //   _id: "1",
    //   title: "हिंदी",
    //   value: "hindi",
    // },
    {
      _id: "2",
      title: "English",
      value: "english",
    },
    ...languageList,
  ];
  const handleClick = async ({ _id: id, value }) => {
    dispatch(changeLanguage(value));
    await checkInternetAndNavigateToLogin(id);
  };

  const checkInternetAndNavigateToLogin = async (language) => {
    try {
      setIsLoading({ [language]: true });
      const result = await healthcheck(kioskId, partnerToken);
      if (result && result.data) {
        if (!result.data.shouldVerify) {
          const { message } = result.data;
          if (message === "OK") {
            navigate("/login");
          } else {
            console.log("Internet is not working");
            ShowToast.error("Internet is not working");
          }
        }
      }
    } catch (error) {
      console.log("Something went wrong while sending OTP");
      console.log(error);
      ShowToast.error("Internet is not working");
    } finally {
      setIsLoading({});
    }
  };

  return (
    <div
      className="flex flex-col w-full justify-start text-center"
      style={{
        height: "100vh",
      }}
    >
      {/* Shree Ganeshji */}
      <Header
      // clientLogo={clientLogo}
      // patientDetails={{ name: "Rajat Kabade", mobile: "+91-9812345678" }}
      />
      <br />
      <div className="text-4xl">welcome to</div>
      <br />
      <br />
      <div className="flex flex-row justify-center">
        <img width={300} style={{ objectFit: "contain" }} src={clientLogo} />
      </div>
      <br />
      <br />
      <div className="text-3xl">select your preferred language</div>
      <br />
      <br />
      <div>
        {finalLangueages.map((language, index) => {
          return (
            <React.Fragment key={index}>
              <IconButton
                isLoading={isLoading[language?._id]}
                buttonText={language?.title}
                onClick={() => {
                  handleClick(language);
                }}
              />
              <br />
              <br />
            </React.Fragment>
          );
        })}
      </div>
    </div>
  );
};
