import React, { useState, useRef, useEffect } from "react";
import DiseaseButton from "./DiseaseButton";
import { diseasesMap } from "../utils/diseaseMap";

export default ({ reportData, onChange }) => {
  const { test } = reportData;
  const [tabSelected, setTabSelected] = useState({
    currentTab: 1,
    noTabs: 3,
  });

  const wrapperRef = useRef(null);
  const canvasRef = useRef();
  const [selectedTabTestData, setSelectedTabTestData] = useState(test[0]);
  const [selectedDisease, setSelectedDisease] = useState([
    ...selectedTabTestData?.diseases,
  ]);
  const [imageUrl, setImageUrl] = useState("");

  const handleKeyDown = (e) => {
    if (e.keyCode === 39) {
      if (wrapperRef.current && wrapperRef.current.contains(e.target)) {
        if (
          tabSelected.currentTab >= 1 &&
          tabSelected.currentTab < tabSelected.noTabs
        ) {
          setTabSelected({
            ...tabSelected,
            currentTab: tabSelected.currentTab + 1,
          });
        } else {
          setTabSelected({
            ...tabSelected,
            currentTab: 1,
          });
        }
      }
    }

    if (e.keyCode === 37) {
      if (wrapperRef.current && wrapperRef.current.contains(e.target)) {
        if (
          tabSelected.currentTab > 1 &&
          tabSelected.currentTab <= tabSelected.noTabs
        ) {
          setTabSelected({
            ...tabSelected,
            currentTab: tabSelected.currentTab - 1,
          });
        } else {
          setTabSelected({
            ...tabSelected,
            currentTab: tabSelected.noTabs,
          });
        }
      }
    }
  };

  const onDiseaseClickHandler = (disease) => {
    if (
      selectedDisease.find(
        (selectedDiseaseInner) => selectedDiseaseInner._id === disease?._id
      )
    ) {
      setSelectedDisease([
        ...selectedDisease.filter(
          (selectedDiseaseInner) => selectedDiseaseInner._id !== disease?._id
        ),
      ]);
    } else {
      setSelectedDisease([...selectedDisease, disease]);
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  });

  useEffect(() => {
    if (!imageUrl) {
      return;
    }

    let FIXED_HEIGHT = 300; // You can set this value to whatever height you want
    const screenHeight = window.innerHeight;
    if (screenHeight < 1000) {
      FIXED_HEIGHT = 200;
    }
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");

    const image = new Image();
    image.src = imageUrl;
    // image.onloadstart = () => {
    //   setLoadingImage(true);
    // };

    image.onload = () => {
      // setLoadingImage(false);
      // Scale based on the fixed height
      const scale = FIXED_HEIGHT / image.height;

      // Set canvas dimensions
      canvas.height = FIXED_HEIGHT;
      canvas.width = image.width * scale;

      // Draw the image on canvas
      context.clearRect(0, 0, canvas.width, canvas.height);
      context.drawImage(image, 0, 0, canvas.width, canvas.height);

      // Annotations
      drawAnnotations(context, 0, 0, scale);
    };
  }, [imageUrl, selectedDisease, selectedTabTestData]);

  const drawRectangle = (ctx, coordinates, color, offsetX, offsetY, scale) => {
    const [x1, y1, x2, y2] = coordinates;
    ctx.globalAlpha = 0.7;
    ctx.fillStyle = color;
    ctx.fillRect(
      offsetX + x1 * scale,
      offsetY + y1 * scale,
      (x2 - x1) * scale,
      (y2 - y1) * scale
    );
  };

  const fillInDiseaseInformation = (diseases) => {
    const diseaseMap = {};

    diseases.forEach((disease) => {
      const diseaseType = disease.name?.toLowerCase();
      if (Object.keys(diseasesMap).includes(diseaseType)) {
        if (!diseaseMap[diseaseType]) {
          diseaseMap[diseaseType] = [];
        }
        diseaseMap[diseaseType].push(disease);
      }
    });
    console.log("Shree Ganesh");
    console.log(diseaseMap);
    return diseaseMap;
  };

  const getTreatmentsColor = (disease) => {
    if (diseasesMap[disease?.toLowerCase()]) {
      return diseasesMap[disease?.toLowerCase()].color;
    } else {
      return "#ffffff";
    }
  };

  const drawAnnotations = (context, offsetX, offsetY, scale) => {
    const diseaseMap = fillInDiseaseInformation(selectedDisease);
    selectedDisease.forEach((diseaseType) => {
      const diseases = diseaseMap[diseaseType?.name?.toLowerCase()];
      const color = diseaseType?.color;
      // getTreatmentsColor(diseaseType);
      if (diseases) {
        diseases.forEach((disease) => {
          const coordinates = disease.coordinates;
          coordinates.forEach((coord) =>
            drawRectangle(context, coord, color, offsetX, offsetY, scale)
          );
        });
      }
    });
  };

  const getImageByAngleIndex = () => {
    const anglesList = ["front", "upper", "lower"];
    if (reportData && reportData.test) {
      const path = reportData.test.find(
        (data) =>
          data.angle?.toLowerCase() === anglesList[tabSelected.currentTab - 1]
      );
      if (path && path.base_file_url) {
        setImageUrl(path.base_file_url);
      }
    }
  };

  useEffect(() => {
    getImageByAngleIndex();
  }, [reportData, tabSelected.currentTab]);

  return (
    <>
      {/*<!-- Component: Pill lg sized tab --> */}
      <div className="w-4/5">
        <section className="max-w-full" aria-multiselectable="false">
          <div className="w-full flex flex-row justify-center">
            <div className="w-3/5 text-center bg-[#EEF6FF] p-4 rounded ">
              <ul
                className="flex items-center justify-evenly"
                role="tablist"
                ref={wrapperRef}
              >
                <li className="" role="presentation">
                  <button
                    className={`inline-flex h-12 items-center justify-center gap-2 whitespace-nowrap rounded px-6 text-sm font-medium tracking-wide transition duration-300 focus-visible:outline-none disabled:cursor-not-allowed ${
                      tabSelected.currentTab === 1
                        ? "bg-blue-500 text-white hover:bg-blue-600 focus:bg-blue-700 disabled:bg-blue-300"
                        : "w-full justify-self-center stroke-slate-700 text-slate-700 hover:bg-blue-50 hover:stroke-blue-500 hover:text-blue-500 focus:bg-blue-50 focus:stroke-blue-600 focus:text-blue-600 disabled:text-blue-300"
                    }`}
                    id="tab-label-1e"
                    role="tab"
                    aria-setsize="3"
                    aria-posinset="1"
                    tabindex={`${tabSelected.currentTab === 1 ? "0" : "-1"}`}
                    aria-controls="tab-panel-1e"
                    aria-selected={`${
                      tabSelected.currentTab === 1 ? "true" : "false"
                    }`}
                    onClick={() => {
                      setTabSelected({ ...tabSelected, currentTab: 1 });
                      setSelectedTabTestData(test[0]);
                      onChange(0);
                      setSelectedDisease([...test[0]?.diseases]);
                    }}
                  >
                    <span style={{ fontWeight: "bold", fontSize: "1.5em" }}>
                      Front
                    </span>
                  </button>
                </li>
                {test?.length > 1 ? (
                  <>
                    <li className="" role="presentation">
                      <button
                        className={`inline-flex h-12 items-center justify-center gap-2 whitespace-nowrap rounded px-6 text-sm font-medium tracking-wide transition duration-300 focus-visible:outline-none disabled:cursor-not-allowed ${
                          tabSelected.currentTab === 2
                            ? "bg-blue-500 text-white hover:bg-blue-600 focus:bg-blue-700 disabled:bg-blue-300"
                            : "w-full justify-self-center stroke-slate-700 text-slate-700 hover:bg-blue-50 hover:stroke-blue-500 hover:text-blue-500 focus:bg-blue-50 focus:stroke-blue-600 focus:text-blue-600 disabled:text-blue-300"
                        }`}
                        id="tab-label-2e"
                        role="tab"
                        aria-setsize="3"
                        aria-posinset="2"
                        tabindex={`${
                          tabSelected.currentTab === 2 ? "0" : "-1"
                        }`}
                        aria-controls="tab-panel-2e"
                        aria-selected={`${
                          tabSelected.currentTab === 2 ? "true" : "false"
                        }`}
                        onClick={() => {
                          setTabSelected({ ...tabSelected, currentTab: 2 });
                          setSelectedTabTestData(test[1]);
                          onChange(1);
                          setSelectedDisease([...test[1]?.diseases]);
                        }}
                      >
                        <span style={{ fontWeight: "bold", fontSize: "1.5em" }}>
                          Upper
                        </span>
                      </button>
                    </li>
                    <li className="" role="presentation">
                      <button
                        className={`inline-flex h-12 items-center justify-center gap-2 whitespace-nowrap rounded px-6 text-sm font-medium tracking-wide transition duration-300 focus-visible:outline-none disabled:cursor-not-allowed ${
                          tabSelected.currentTab === 3
                            ? "bg-blue-500 text-white hover:bg-blue-600 focus:bg-blue-700 disabled:bg-blue-300"
                            : "w-full justify-self-center stroke-slate-700 text-slate-700 hover:bg-blue-50 hover:stroke-blue-500 hover:text-blue-500 focus:bg-blue-50 focus:stroke-blue-600 focus:text-blue-600 disabled:text-blue-300"
                        }`}
                        id="tab-label-3e"
                        role="tab"
                        aria-setsize="3"
                        aria-posinset="3"
                        tabindex={`${
                          tabSelected.currentTab === 3 ? "0" : "-1"
                        }`}
                        aria-controls="tab-panel-3e"
                        aria-selected={`${
                          tabSelected.currentTab === 3 ? "true" : "false"
                        }`}
                        onClick={() => {
                          setTabSelected({ ...tabSelected, currentTab: 3 });
                          setSelectedTabTestData(test[2]);
                          onChange(2);
                          setSelectedDisease([...test[2]?.diseases]);
                        }}
                      >
                        <span style={{ fontWeight: "bold", fontSize: "1.5em" }}>
                          Lower
                        </span>
                      </button>
                    </li>
                  </>
                ) : (
                  <></>
                )}
              </ul>
            </div>
          </div>
          <div
            className="w-full flex flex-row justify-center"
            style={{ height: "30vh" }}
          >
            <div
              className={`px-6 py-4 w-3/5 bg-white flex flex-row justify-center`}
              id="tab-panel-1e"
              aria-hidden={`${tabSelected.currentTab === 1 ? "true" : "false"}`}
              role="tabpanel"
              aria-labelledby="tab-label-1e"
              tabindex="-1"
            >
              {/* <img
                src={selectedTabTestData["base_file_url"]}
                style={{ objectFit: "contain" }}
              /> */}

              <canvas
                ref={canvasRef}
                className="image image-contain"
                style={{ objectFit: "contain" }}
                key={imageUrl}
              ></canvas>
              {/* <br /> */}
            </div>
          </div>
          <div className="w-full flex flex-row justify-center">
            {selectedTabTestData?.diseases.map((disease, index) => {
              const { name, color, _id } = disease;
              return (
                <>
                  <DiseaseButton
                    key={index}
                    buttonText={name?.replace("_", " ")}
                    color={color}
                    isSelected={
                      !!selectedDisease.find(
                        (selectedDiseaseInner) =>
                          selectedDiseaseInner._id === _id
                      )
                    }
                    onClick={() => {
                      onDiseaseClickHandler(disease);
                    }}
                  />
                  &nbsp; &nbsp;
                </>
              );
            })}
          </div>
        </section>
      </div>
      {/*<!-- End Pill lg sized tab --> */}
    </>
  );
};
