import Header from "../components/Header";
import clientLogo from "../assets/client-logo.png";
import frontOverlay from "../assets/front-overlay.png";
import upperOverlay from "../assets/upper-overlay.png";
import lowerOverlay from "../assets/lower-overlay.png";
import Button from "../components/Button";
import Input from "../components/Input";
import { useSelector } from "react-redux";
import {
  getAngleCount,
  getAutoHeightAdjust,
  getLanguage,
  getLocation,
  getTestId,
  getToken,
  getUserDetails,
} from "../store/rootReducer";
import { useEffect, useState } from "react";
import socketService from "../services/socket.service";
import audioGuide from "../services/audio.service";
import { useNavigate } from "react-router-dom";
import axios from "axios";

export default () => {
  const { name, mobile } = useSelector(getUserDetails);
  const [currentAngle, setCurrentAngle] = useState("front");
  const language = useSelector(getLanguage);
  const autoHeightAdjust = useSelector(getAutoHeightAdjust);
  const testId = useSelector(getTestId);
  const location = useSelector(getLocation);
  const authToken = useSelector(getToken);
  const angleCount = useSelector(getAngleCount);
  const navigate = useNavigate();
  const videoFrameData = `${process.env.REACT_APP_PI_HOST_URL}:${process.env.REACT_APP_PI_VIDEO_PORT}/stream.mjpg`;
  let capturingTimer;
  useEffect(() => {
    console.log("AUTO height adjustment", autoHeightAdjust);

    setTimeout(() => socketService.detectJaw(angleCount), 500);
    socketService.on("currentAngle", handleCurrentAngle);
    socketService.on("scanComplete", handleScanComplete);

    socketService.on("hold", handleHoldAngle);
    socketService.on("capture", handleCapture);
    socketService.on("imageUpload", handleFileUpload);

    // Cleanup when component is unmounted
    return () => {
      socketService.removeListener("currentAngle", handleCurrentAngle);
      socketService.removeListener("scanComplete", handleScanComplete);

      socketService.removeListener("hold", handleHoldAngle);
      socketService.removeListener("capture", handleCapture);
      socketService.removeListener("imageUpload", handleFileUpload);
    };
  }, []);
  const handleCurrentAngle = async (angle) => {
    await audioGuide.playSound(angle.toUpperCase());
    setCurrentAngle(angle);
  };
  const handleHoldAngle = () => {
    audioGuide.playSound("stay_still");
    if (capturingTimer) {
      clearInterval(capturingTimer);
      capturingTimer = null;
    }
    capturingTimer = setInterval(() => {
      audioGuide.playSound("hold_timer");
    }, 1000);
  };

  const handleCapture = () => {
    if (capturingTimer) {
      clearInterval(capturingTimer);
      capturingTimer = null;
    }
    audioGuide.playSound("camera_click");
  };

  const handleFileUpload = async (data) => {
    try {
      const blob = new Blob([new Uint8Array(data.byteObject)], {
        type: "image/jpg",
      });
      const formData = new FormData();
      formData.append("input_image", blob); //for image test
      formData.append("testId", testId);
      formData.append("angleCount", angleCount);
      formData.append("angle", data.angle); //for image test

      await axios({
        method: "post",
        url: `${process.env.REACT_APP_API_HOST_URL}/v1/scan/uploadImage`,
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${authToken}`,
        },
      });
    } catch (error) {
      console.log("Something went wrong while uploading file", error);
    }
  };

  const handleScanComplete = () => {
    // history.push("/report");
    navigate("/report");
  };
  const overlayMap = {
    'front': frontOverlay,
    'upper': upperOverlay,
    'lower': lowerOverlay,
  }
  return (
    <div
      className="flex flex-col w-full justify-start text-center animate-myBounce"
      style={{
        height: "100vh",
      }}
    >
      {/* Shree Ganeshji */}
      <Header clientLogo={clientLogo} patientDetails={{ name, mobile }} />
      <br />
      <div className="text-6xl font-bold text-[#344277]">Step 3</div>
      <br />
      <div className="tracking-wide text-[#7C87AF]">
        Fit your teeth in the overlay
        <br />
        Hold steady until you hear camera shutter sound
      </div>
      <br />
      <div
        className="flex flex-row w-full justify-center text-center"
        // style={{ height: "75vh" }}
      >
        <div className="bg-white w-4/5 flex flex-col justify-evenly text-white">
          <div class="imageWrapper">
            <img
              className="overlayImage"
              style={{
                // height: "75vh",
                objectFit: "cover",
                width: "100%",
              }}
              alt="Received Video Frame"
              src={videoFrameData}
            />
            <img className="overlayImage" src={overlayMap[currentAngle]}/>
          </div>
        </div>
      </div>
      {/* <div className="text-[#344277] text-2xl">
        Does your face head fit inside the overlay?
      </div>

      <br />
      <div className="flex flex-row justify-evenly">
        <Button buttonText={"Yes"} width={"w-1/5"} />
      </div> */}
    </div>
  );
};
