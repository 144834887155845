export default ({ buttonText, onClick, color, isSelected }) => {
  return isSelected ? (
    <button
      onClick={onClick}
      style={{ backgroundColor: color, fontWeight: "bold" }}
      className={`inline-flex items-center justify-center h-12 gap-2 px-6 text-sm font-medium tracking-wide text-white transition duration-300 rounded whitespace-nowrap  w-1/4`}
    >
      <span>{buttonText}</span>
    </button>
  ) : (
    <button
      onClick={onClick}
      style={{
        borderColor: color,
        color: color,
        fontWeight: "bold",
      }}
      className={`inline-flex items-center justify-center h-12 gap-2 px-6 text-sm font-medium tracking-wide transition duration-300 border rounded focus-visible:outline-none whitespace-nowrap w-1/4`}
    >
      <span>{buttonText}</span>
    </button>
  );
};
