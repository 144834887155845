export default ({ buttonText, width, onClick }) => {
  return (
    <button
      onClick={onClick}
      className={`inline-flex items-center justify-center h-12 gap-2 px-6 text-sm font-medium tracking-wide text-white transition duration-300 rounded whitespace-nowrap bg-blue-900  focus-visible:outline-none disabled:cursor-not-allowed disabled:border-blue-300 disabled:bg-blue-300 focus:bg-blue-500  disabled:shadow-none ${
        width || "w-1/4"
      }`}
    >
      <span>{buttonText}</span>
    </button>
  );
};
