import { useState } from "react";

export default ({ reportData, selectedAngle }) => {
  const { test } = reportData;
  const diseasesList = test[selectedAngle]?.diseases;
  const [expandedDisease, setExpandedDisease] = useState(-1);
  console.log("Shree Ganesh");
  console.log(expandedDisease);
  return (
    <>
      <div className="w-4/5 bg-[#FDEACE]">
        <section className="w-full divide-y rounded divide-slate-200 ">
          {diseasesList.map((disease, index) => {
            const { name, description, color } = disease;
            return (
              <details
                key={index}
                className="p-4 group"
                open={expandedDisease === index}
                onClick={() => {
                  setExpandedDisease(index);
                  // setExpandedDisease(-1);
                  // setTimeout(() => {
                  //   setExpandedDisease(index);
                  // }, 100);
                }}
              >
                <summary
                  style={{
                    color: "black",
                    fontWeight: "bold",
                    textAlign: "start",
                  }}
                  className="relative cursor-pointer list-none pr-8 font-medium text-slate-700 transition-colors duration-300 focus-visible:outline-none group-hover:text-slate-900  [&::-webkit-details-marker]:hidden"
                >
                  <div className="flex flex-row items-center">
                    <div
                      className="p-1 rounded-full mr-2"
                      style={{
                        backgroundColor: color,
                        width: "2em",
                        textAlign: "center",
                        color: "white",
                      }}
                    >
                      {index + 1}
                    </div>
                    &nbsp;
                    {name?.replace("_", " ")}
                  </div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="absolute right-0 w-4 h-4 transition duration-300 top-1 shrink-0 stroke-slate-700 group-open:rotate-45"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    aria-labelledby="title-ac01 desc-ac01"
                  >
                    <title id="title-ac01">Open icon</title>
                    <desc id="desc-ac01">
                      icon that represents the state of the summary
                    </desc>
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M12 4v16m8-8H4"
                    />
                  </svg>
                </summary>
                <div dangerouslySetInnerHTML={{ __html: description }} />
              </details>
            );
          })}
        </section>
      </div>
    </>
  );
};
