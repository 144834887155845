import React from "react";

const Select = ({ options, label, selectedOption, onChange }) => {
  return (
    <>
      <div className="w-full flex flex-row justify-center">
        <div className="relative my-6 w-2/4">
          <select
            onChange={onChange}
            id="id-10"
            name="id-10"
            required
            value={selectedOption}
            className="peer relative h-12 w-full appearance-none rounded border border-slate-200 bg-white px-4 text-slate-500 outline-none transition-all autofill:bg-white focus:border-emerald-500 focus-visible:outline-none focus:focus-visible:outline-none disabled:cursor-not-allowed disabled:bg-slate-50 disabled:text-slate-400"
          >
            <option value="" disabled selected></option>
            {options.map((optionItem, index) => {
              const { name, _id } = optionItem;
              return (
                <option key={index} value={_id}>
                  {name}
                </option>
              );
            })}
          </select>
          <label
            for="id-10"
            className="pointer-events-none absolute top-3 left-2 z-[1] px-2 text-base text-slate-400 transition-all before:absolute before:top-0 before:left-0 before:z-[-1] before:block before:h-full before:w-full before:bg-white before:transition-all peer-required:after:text-pink-500 peer-required:after:content-['\00a0*'] peer-valid:-top-2 peer-valid:text-xs peer-focus:-top-2 peer-focus:text-xs peer-focus:text-emerald-500 peer-disabled:cursor-not-allowed peer-disabled:text-slate-400 peer-disabled:before:bg-transparent"
          >
            {label}
          </label>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="pointer-events-none absolute top-3.5 right-2 h-5 w-5 fill-slate-400 transition-all peer-focus:fill-emerald-500 peer-disabled:cursor-not-allowed"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-labelledby="title-10 description-10"
            role="graphics-symbol"
          >
            <title id="title-10">Arrow Icon</title>
            <desc id="description-10">Arrow icon of the select list.</desc>
            <path
              fillRule="evenodd"
              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
              clipRule="evenodd"
            />
          </svg>
        </div>
      </div>
    </>
  );
};

export default Select;
