import Header from "../components/Header";
import clientLogo from "../assets/client-logo.png";
import Button from "../components/Button";
import Input from "../components/Input";
import { useState } from "react";
import ShowToast from "../components/ShowToast";
import IconButton from "../components/IconButton";
import { sendOtp, verifyOtp } from "../services/api.service";
import { useDispatch, useSelector } from "react-redux";
import {
  getBypassLogin,
  getKioskId,
  getLanguage,
  getUserDetails,
  setPatientNumber,
  setTestId,
  setToken,
  setUserDetails,
} from "../store/rootReducer";
import { useLocation, useNavigate } from "react-router-dom";
import { labelsMap } from "../utils/i18n";

export default () => {
  const [formData, setFormData] = useState({
    opt: "",
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedInput, setSelectedInput] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const kioskId = useSelector(getKioskId);
  const isBypassLogin = useSelector(getBypassLogin);
  const language = useSelector(getLanguage);
  // const { name, mobile } = useSelector(getUserDetails);
  const onKeyboardInputChange = (key, value) => {
    setFormData({
      ...formData,
      [key]: value,
    });
  };
  const isFormValid = () => {
    let isValid = true;
    const { otp } = formData;
    if (!otp) {
      isValid = false;
      return;
    }
    return isValid;
  };
  const handleClick = async () => {
    console.log("Shree Ganesh");
    // console.log("Shree Ganesh");
    // console.log(formData);
    try {
      const { otp } = formData;
      const { name, mobile } = location?.state;
      const isValid = isFormValid();
      if (!isValid) {
        ShowToast.error("Invalid Input. Please provide correct values");
      } else {
        setIsLoading(true);
        const {
          data: { token, testId, isValid },
        } = await verifyOtp(name, mobile, kioskId, otp);
        if (isValid) {
          dispatch(setToken(token));
          dispatch(setTestId(testId));
          dispatch(setUserDetails({ name, mobile }));
          navigate("/scan");
        } else {
          ShowToast.error(LOGIN_MESSAGE_INVALID_OTP);
        }
      }
    } catch (error) {
      ShowToast.error(LOGIN_MESSAGE_INVALID_OTP);
    } finally {
      setIsLoading(false);
    }
  };
  const {
    LOGIN_HEADING,
    LOGIN_SUBHEADING,
    LOGIN_INPUT_NAME,
    LOGIN_INPUT_MOBILE,
    LOGIN_INPUT_OTP,
    LOGIN_BUTTON_SEND_OTP,
    LOGIN_BUTTON_SUBMIT_OTP,
    LOGIN_BUTTON_RESEND_OTP,
    LOGIN_BUTTON_EDIT_NUMBER,
    LOGIN_MESSAGE_OTP_SENT,
    LOGIN_MESSAGE_INVALID_OTP,
    LOGIN_MESSAGE_OTP_ERROR,
    LOGIN_MESSAGE_INVALID_DATA,
    LOGIN_MESSAGE_OTP_SUBMIT_ERROR,
  } = labelsMap[language];
  return (
    <div
      className="flex flex-col w-full justify-start text-center "
      style={{
        height: "100vh",
      }}
    >
      {/* Shree Ganeshji */}
      <Header
        clientLogo={clientLogo}
        // patientDetails={{ name: "Rajat Kabade", mobile: "+91-9812345678" }}
      />
      <br />
      <div className="text-6xl font-bold text-[#344277] flex flex-row justify-center">
        {LOGIN_HEADING} &nbsp;
        <div className="text-6xl animate-[wiggle_1s_ease-in-out_infinite]">
          👋
        </div>
      </div>
      <br />
      <div className="tracking-wide text-[#7C87AF]">
        You are 3 steps away from magical AI dental scan
      </div>
      <br />

      <Input
        placeholder={LOGIN_INPUT_OTP}
        label={LOGIN_INPUT_OTP}
        onChange={(event) => {
          const { value } = event?.target;
          onKeyboardInputChange("otp", value);
        }}
      />
      <br />
      <div>
        <IconButton
          buttonText={LOGIN_BUTTON_SUBMIT_OTP}
          isLoading={isLoading}
          onClick={handleClick}
        />
      </div>
    </div>
  );
};
