import Header from "../components/Header";
import clientLogo from "../assets/client-logo.png";
import Button from "../components/Button";
import Input from "../components/Input";
import DiseasesList from "../components/DiseasesList";
import Tab from "../components/Tab";
import { useEffect, useState } from "react";
import { getReport } from "../services/api.service";
import { useSelector } from "react-redux";
import {
  getLanguage,
  getTestId,
  getToken,
  getUserDetails,
} from "../store/rootReducer";
import Loader from "../components/Loader";
import IconButton from "../components/IconButton";
import { useNavigate } from "react-router-dom";

export default () => {
  const testId = useSelector(getTestId);
  const authToken = useSelector(getToken);
  const [loadingReportData, setLoadingReportData] = useState(true);
  const [activeStep, setActiveStep] = useState(0);
  const [imageUrl, setImageUrl] = useState("");
  const [reportData, setReportData] = useState();
  const [diseasesList, setDiseasesList] = useState([]);
  const language = useSelector(getLanguage);
  const { name, mobile } = useSelector(getUserDetails);
  const navigate = useNavigate();
  let stopWaiting = false;
  const waitTillTestCompletes = async () => {
    try {
      if (stopWaiting) {
        return;
      }
      const result = await getReport(authToken, testId);
      if (result && result.data && result.data.completed) {
        setReportData(result.data);
        setLoadingReportData(false);
        return true;
      } else {
        await new Promise((resolve) => setTimeout(resolve, 5000));
        waitTillTestCompletes();
      }
    } catch (error) {
      await new Promise((resolve) => setTimeout(resolve, 5000));
      waitTillTestCompletes();
    }
  };
  useEffect(() => {
    waitTillTestCompletes();
    return () => {
      stopWaiting = true;
    };
  }, []);

  const onAngleChangeHandler = (index) => {
    setActiveStep(index);
  };
  return (
    <div
      className="flex flex-col w-full justify-start text-center animate-myBounce"
      style={{
        height: "100vh",
      }}
    >
      {/* Shree Ganeshji */}
      <Header clientLogo={clientLogo} patientDetails={{ name, mobile }} />
      <br />
      <div className="text-6xl font-bold text-[#344277]">
        AI SCREENING RESULT
      </div>
      <br />
      {loadingReportData ? (
        <>
          <Loader />
        </>
      ) : (
        <>
          <div className="flex flex-row justify-center">
            <Tab reportData={reportData} onChange={onAngleChangeHandler} />
          </div>
          <br />
          <div className="flex flex-row justify-center">
            <DiseasesList reportData={reportData} selectedAngle={activeStep} />
          </div>
          <br />
          <div className="flex flex-row justify-evenly">
            {/* <Button buttonText={"Download Report"} width={"w-2/5"} /> */}
            <IconButton
              buttonText={"Exit"}
              onClick={() => {
                navigate("/home");
              }}
            />
          </div>
        </>
      )}
    </div>
  );
};
