import { HashRouter as Router, Routes, Route } from "react-router-dom";
import Header from "./components/Header";
import DoctorLogin from "./containers/DoctorLogin";
import Home from "./containers/Home";
import Login from "./containers/Login";
import OnboardingStep1 from "./containers/OnboardingStep1";
import OnboardingStep2 from "./containers/OnboardingStep2";
import Report from "./containers/Report";
import { ToastContainer, toast } from "react-toastify";
import KioskSelection from "./containers/KioskSelection";
import VerifyOtp from "./containers/verify-otp";
import { useDispatch, useSelector } from "react-redux";
import {
  getKioskId,
  getPartnerToken,
  setAutoHeightAdjust,
  setBypassLogin,
  setBypassOTP,
  setKioskHarwareConfig,
  setKioskName,
  setLanguageList,
  setLocation,
  setPartnerMobile,
} from "./store/rootReducer";
import { getKioskConfig } from "./services/api.service";
import { useEffect } from "react";
import Scan from "./containers/Scan";
import socketService from "./services/socket.service";

function App() {
  const dispatch = useDispatch();

  const kioskId = useSelector(getKioskId);
  const partnerToken = useSelector(getPartnerToken);

  const updateKioskHWConfig = async () => {
    if (kioskId && partnerToken) {
      try {
        const { data } = await getKioskConfig(kioskId, partnerToken);
        const {
          autoHeightAdjust,
          code,
          languages,
          bypassOtp,
          bypasslogin,
          name,
          mobile,
          jawThreshold,
          faceThreshold,
          actuatorConfig,
          servoAngle,
          ledColors,
        } = data || {};
        dispatch(setAutoHeightAdjust(autoHeightAdjust));
        dispatch(setLocation(code));
        dispatch(
          setLanguageList(
            languages.map((language) => {
              return language;
            })
          )
        );
        dispatch(setBypassOTP(bypassOtp));
        dispatch(setBypassLogin(bypasslogin));
        dispatch(setKioskName(name));
        dispatch(setPartnerMobile(mobile ? mobile : "7797555777"));
        dispatch(
          setKioskHarwareConfig({
            jawThreshold,
            faceThreshold,
            actuatorConfig,
            servoAngle,
            ledColors,
          })
        );
        // send the HW config values to server
        socketService.setKioskHarwareConfig(
          jawThreshold,
          faceThreshold,
          actuatorConfig,
          servoAngle,
          ledColors
        );
      } catch (error) {
        console.log("Something went wrong while setting config values");
        console.log(error);
      }
    }
  };

  useEffect(() => {
    updateKioskHWConfig();
  }, [kioskId, partnerToken]);

  return (
    <div>
      <Router>
        <Routes>
          {/* <Route path="/" element={<Home />} /> */}
          <Route index path="/" element={<DoctorLogin />} />
          <Route path="/kiosk-selection" element={<KioskSelection />} />
          <Route path="/home" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/verify-otp" element={<VerifyOtp />} />
          <Route path="/onboarding-step1" element={<OnboardingStep1 />} />
          <Route path="/onboarding-step2" element={<OnboardingStep2 />} />
          <Route path="/scan" element={<Scan />} />
          <Route path="/report" element={<Report />} />
        </Routes>
      </Router>
      {/* <ToastContainer /> */}
      {/* <Home /> */}
      {/* <Login /> */}
      {/* <DoctorLogin /> */}
      {/* <Onboarding /> */}
      {/* <Report /> */}
      {/* <button className="btn">Shree Ganesh</button> */}
      <ToastContainer />
    </div>
  );
}

export default App;
