import Header from "../components/Header";
import clientLogo from "../assets/client-logo.png";
import Button from "../components/Button";
import Input from "../components/Input";
import { useSelector } from "react-redux";
import { getUserDetails } from "../store/rootReducer";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import socketService from "../services/socket.service";
import audioGuide from "../services/audio.service";

export default () => {
  const { name, mobile } = useSelector(getUserDetails);
  const [showNextNavigationButton, setShowNextNavigationButton] =
    useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    socketService.adjustHeight();
    socketService.on("heightAdjustment", handleAdjustHeightResult);
    audioGuide.playSound("adjust_camera_height");
  }, []);

  const handleAdjustHeightResult = (_) => {
    setShowNextNavigationButton(true);
  };

  const handleClick = () => {
    navigate("/scan");
  };

  const handleTouchStart = (direction) => {
    if (direction === "up") {
      socketService.motorUp();
    } else {
      socketService.motorDown();
    }
    setTimeout(()=>{
      handleTouchEnd()
    }, 3000);
  };

  const handleTouchEnd = () => {
    socketService.motorStop();
  };
  const videoFrameData = `${process.env.REACT_APP_PI_HOST_URL}:${process.env.REACT_APP_PI_VIDEO_PORT}/stream.mjpg`;
  return (
    <div
      className="flex flex-col w-full justify-start text-center animate-myBounce"
      style={{
        height: "100vh",
      }}
    >
      {/* Shree Ganeshji */}
      <Header clientLogo={clientLogo} patientDetails={{ name, mobile }} />
      <br />
      <div className="text-6xl font-bold text-[#344277]">Step 2</div>
      <br />
      <div className="tracking-wide text-[#7C87AF]">
        Position yourself as per the guiding video
      </div>
      <br />
      {/* <div className="flex flex-row justify-evenly">
        <Button buttonText={"Move Up"} width={"w-2/5"} onClick={() => handleTouchStart("up")} />
      </div> */}
      <div
        className="flex flex-row w-full justify-center text-center"
        style={{ height: "75vh" }}
      >
        <div className="bg-black w-4/5 flex flex-col justify-evenly text-white">
          <div
            onClick = {() => handleTouchStart("up")}
            // onTouch={() => handleTouchStart("up")}
            // onTouchEnd={handleTouchEnd}
            className="flex flex-row justify-center"
          >
            <svg
              className="-rotate-90"
              width="75"
              height="60"
              viewBox="0 0 39 44"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.809625 0.319996H17.8016L38.7296 21.824L17.8016 43.328H0.809625L21.8336 21.824L0.809625 0.319996Z"
                fill="white"
              />
            </svg>
          </div>
          <span>Move Up</span>
          {/* <div className="flex flex-row justify-evenly">
            <Button buttonText={"Move Up"} width={"w-2/5"} onClick={() => handleTouchStart("up")} />
          </div> */}
          <img
            style={{
              // height: "75vh",
              objectFit: "cover",
              width: "100%",
            }}
            alt="Received Video Frame"
            src={videoFrameData}
          />
          <span>Move Down</span>
          <div
            onClick = {() => handleTouchStart("down")} 
            className="flex flex-row justify-center">
            <svg
              className="rotate-90"
              width="75"
              height="60"
              viewBox="0 0 39 44"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.809625 0.319996H17.8016L38.7296 21.824L17.8016 43.328H0.809625L21.8336 21.824L0.809625 0.319996Z"
                fill="white"
              />
            </svg>
          </div>
        </div>
      </div>
      <br />
      {showNextNavigationButton ? (
        <>
          <div className="text-[#344277] text-2xl">
            Does your face head fit inside the frame?
          </div>
          <br />
          <div className="flex flex-row justify-evenly">
            <Button buttonText={"Yes"} width={"w-1/5"} onClick={handleClick} />
          </div>
        </>
      ) : (
        <></>
      )}
    </div>
  );
};
