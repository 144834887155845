import scanOLogo from "../assets/scano-logo.png";
import personIcon from "../assets/person-icon.svg";
import phoneIcon from "../assets/phone-icon.svg";

export default ({ clientLogo, patientDetails }) => {
  return (
    <div className="w-full flex flex-row p-5">
      <div className="w-1/4">
        {clientLogo ? (
          <img width={150} style={{ objectFit: "contain" }} src={clientLogo} />
        ) : (
          <></>
        )}
      </div>
      <div className="w-2/4 flex flex-row justify-center">
        {patientDetails ? (
          <>
            <div className="w-2/4 flex flex-col justify-center">
              <div className="flex flex-row justify-center">
                <img
                  width={20}
                  style={{ objectFit: "contain" }}
                  src={personIcon}
                />
                &nbsp; {patientDetails?.name}
              </div>
            </div>
            <div className="w-2/4 flex flex-col justify-center">
              <div className="flex flex-row justify-center">
                <img
                  width={20}
                  style={{ objectFit: "contain" }}
                  src={phoneIcon}
                />
                &nbsp; {patientDetails?.mobile}
              </div>
            </div>
          </>
        ) : (
          <></>
        )}
      </div>

      <div className="w-1/4 flex flex-row-reverse">
        <img width={150} style={{ objectFit: "contain" }} src={scanOLogo} />
      </div>
    </div>
  );
};
