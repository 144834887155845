import { createSlice } from "@reduxjs/toolkit";

const anglesList = ["front", "upper", "lower"];

const localStoragePartnerToken =
  JSON.parse(localStorage.getItem("partnerToken")) || "";
const localStorageKioskId = JSON.parse(localStorage.getItem("kioskId")) || "";
const localStorageClinicId = JSON.parse(localStorage.getItem("clinicId")) || "";

export const root = createSlice({
  name: "root",
  initialState: {
    language: "english",
    token: "",
    testId: "",
    currentAngleIndex: 0,
    name: "",
    mobile: "",
    testMode: "VIDEO",
    angleCount: 3,
    autoHeightAdjust: false,
    location: null,
    languageList: [],
    bypassOTP: false,
    bypassLogin: false,
    patientNumber: "",

    jawThreshold: 70,
    faceThreshold: 14000,
    actuatorConfig: {
      actuator_wait_delay: 30,
      actuator_step_size: 2,
    },
    servoAngle: 100,
    ledColors: ["62,62,62", "62,62,62", "62,62,62"],

    kioskId: localStorageKioskId,
    clinicId: localStorageClinicId,
    partnerToken: localStoragePartnerToken,
    partnerMobile: "",
    kioskName: "",

    healthStatus: {
      led: true,
      camera: true,
      actuator: true,
      servo: true,
      internet: true,
    },
  },
  reducers: {
    changeLanguage: (state, { payload }) => {
      state.language = payload;
    },
    setToken: (state, { payload }) => {
      state.token = payload;
    },
    setKioskId: (state, { payload }) => {
      state.kioskId = payload;
      localStorage.setItem("kioskId", JSON.stringify(payload));
    },
    setClinicId: (state, { payload }) => {
      state.clinicId = payload;
      localStorage.setItem("clinicId", JSON.stringify(payload));
    },
    setPartnerToken: (state, { payload }) => {
      state.partnerToken = payload;
      localStorage.setItem("partnerToken", JSON.stringify(payload));
    },
    setTestId: (state, { payload }) => {
      state.testId = payload;
    },
    setCurrentAngle: (state, { payload }) => {
      state.currentAngleIndex = payload;
    },
    setUserDetails: (state, { payload }) => {
      state.name = payload.name;
      state.mobile = payload.mobile;
    },
    setTestMode: (state, { payload }) => {
      state.testMode = payload;
    },
    setLocation: (state, { payload }) => {
      state.location = payload;
      localStorage.setItem("location", payload);
    },
    setLanguageList: (state, { payload }) => {
      state.languageList = payload;
    },
    setAutoHeightAdjust: (state, { payload }) => {
      state.autoHeightAdjust = payload;
      localStorage.setItem("autoHeightAdjust", JSON.stringify(payload));
    },
    setBypassOTP: (state, { payload }) => {
      state.bypassOTP = payload;
    },
    setBypassLogin: (state, { payload }) => {
      state.bypassLogin = payload;
    },
    setPatientNumber: (state, { payload }) => {
      state.patientNumber = payload;
    },
    setAngleCount: (state, { payload }) => {
      state.angleCount = payload;
    },
    setPartnerMobile: (state, { payload }) => {
      state.partnerMobile = payload;
    },
    setKioskName: (state, { payload }) => {
      state.kioskName = payload;
    },

    setKioskHarwareConfig: (state, { payload }) => {
      const {
        jawThreshold,
        faceThreshold,
        actuatorConfig,
        servoAngle,
        ledColors,
      } = payload;
      state.jawThreshold = jawThreshold || 70;
      state.faceThreshold = faceThreshold || 14000;
      state.actuatorConfig = actuatorConfig || {
        actuator_wait_delay: 30,
        actuator_step_size: 2,
      };
      state.servoAngle = servoAngle || 100;
      state.ledColors = ledColors || ["62,62,62", "62,62,62", "62,62,62"];
    },

    resetState: (state, {}) => {
      state.language = "english";
      state.token = "";
      state.testId = "";
      state.currentAngleIndex = 0;
      state.name = "";
      state.mobile = "";
      state.angleCount = 3;
    },
  },
});

export const {
  changeLanguage,
  setPartnerToken,
  setToken,
  setTestId,
  setCurrentAngle,
  setUserDetails,
  resetState,
  setTestMode,
  setLocation,
  setLanguageList,
  setAutoHeightAdjust,
  setKioskId,
  setClinicId,
  setBypassOTP,
  setBypassLogin,
  setPatientNumber,
  setAngleCount,
  setPartnerMobile,
  setKioskName,
  setKioskHarwareConfig,
} = root?.actions;
export const getLanguage = (state) => state?.root?.language;
export const getToken = (state) => state?.root?.token;
export const getPartnerToken = (state) => state?.root?.partnerToken;
export const getTestId = (state) => state?.root?.testId;
export const getTestMode = (state) => state?.root?.testMode;
export const getCurrentAngleIndex = (state) => state?.root?.currentAngleIndex;
export const getCurrentAngle = (state) =>
  anglesList[state?.root?.currentAngleIndex];
export const getUserDetails = (state) => {
  return {
    name: state?.root?.name,
    mobile: state?.root?.mobile,
  };
};
export const getLocation = (state) => state?.root?.location;
export const getLanguageList = (state) => state?.root?.languageList;
export const getAutoHeightAdjust = (state) => state?.root?.autoHeightAdjust;
export const getKioskId = (state) => state?.root?.kioskId;
export const getClinicId = (state) => state?.root?.clinicId;
export const getBypassOTP = (state) => state?.root?.bypassOTP;
export const getBypassLogin = (state) => state?.root?.bypassLogin;
export const getPatientNumber = (state) => state?.root?.patientNumber;
export const getAngleCount = (state) => state?.root?.angleCount;
export const getPartnerMobile = (state) => state?.root?.partnerMobile;
export const getKioskName = (state) => state?.root?.kioskName;
export const getKioskHarwareConfig = (state) => {
  return {
    jawThreshold: state?.root?.jawThreshold || 70,
    faceThreshold: state?.root?.faceThreshold || 14000,
    actuatorConfig: state?.root?.actuatorConfig || {
      actuator_wait_delay: 30,
      actuator_step_size: 2,
    },
    servoAngle: state?.root?.servoAngle || 100,
    ledColors: state?.root?.ledColors || ["62,62,62", "62,62,62", "62,62,62"],
  };
};
export default root?.reducer;
