import Header from "../components/Header";
import clientLogo from "../assets/client-logo.png";
import Button from "../components/Button";
import Input from "../components/Input";
import { useState } from "react";
import ShowToast from "../components/ShowToast";
import IconButton from "../components/IconButton";
import { sendOtp } from "../services/api.service";
import { useDispatch, useSelector } from "react-redux";
import {
  getBypassLogin,
  getKioskId,
  getLanguage,
  setPatientNumber,
  setTestId,
  setToken,
  setUserDetails,
} from "../store/rootReducer";
import { useNavigate } from "react-router-dom";
import { labelsMap } from "../utils/i18n";
import Keyboard from "../components/Keyboard";

export default () => {
  const [formData, setFormData] = useState({
    name: "",
    mobile: "",
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selectedInput, setSelectedInput] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const kioskId = useSelector(getKioskId);
  const language = useSelector(getLanguage);
  const isBypassLogin = useSelector(getBypassLogin);
  const onKeyboardInputChange = (key, value) => {
    setFormData({
      ...formData,
      [key]: value,
    });
  };
  const isFormValid = () => {
    let isValid = true;
    const { name, mobile } = formData;
    if (!name || !mobile) {
      isValid = false;
      return;
    }
    // isValid = mobile.match("^[6-9]{1}[0-9]{9}$");
    return isValid;
  };
  const handleClick = async () => {
    const isValid = isFormValid();
    if (!isValid) {
      ShowToast.error("Invalid Input. Please provide correct values");
    } else {
      setIsLoading(true);
      const { name, mobile } = formData;
      const {
        data: { shouldVerify, token, testId },
      } = await sendOtp(name, mobile, kioskId);
      if (shouldVerify) {
        // GOTO verify otp screen
        navigate("/verify-otp", {
          state: {
            name,
            mobile,
          },
        });
      } else {
        dispatch(setToken(token));
        dispatch(setTestId(testId));
        if (isBypassLogin) {
          dispatch(setPatientNumber(token));
        } else {
          dispatch(setUserDetails({ name, mobile }));
        }
        navigate("/onboarding-step1");
      }
      try {
        // ShowToast.success("SG");
      } catch (error) {
      } finally {
        setIsLoading(false);
      }
    }
  };
  const {
    LOGIN_HEADING,
    LOGIN_SUBHEADING,
    LOGIN_INPUT_NAME,
    LOGIN_INPUT_MOBILE,
    LOGIN_INPUT_OTP,
    LOGIN_BUTTON_SEND_OTP,
    LOGIN_BUTTON_SUBMIT_OTP,
    LOGIN_BUTTON_RESEND_OTP,
    LOGIN_BUTTON_EDIT_NUMBER,
    LOGIN_MESSAGE_OTP_SENT,
    LOGIN_MESSAGE_INVALID_OTP,
    LOGIN_MESSAGE_OTP_ERROR,
    LOGIN_MESSAGE_INVALID_DATA,
    LOGIN_MESSAGE_OTP_SUBMIT_ERROR,
  } = labelsMap[language];
  return (
    <div
      className="flex flex-col w-full justify-start text-center "
      style={{
        height: "100vh",
      }}
    >
      {/* Shree Ganeshji */}
      <Header
        clientLogo={clientLogo}
        // patientDetails={{ name: "Rajat Kabade", mobile: "+91-9812345678" }}
      />
      <br />
      <div className="text-6xl font-bold text-[#344277] flex flex-row justify-center">
        {LOGIN_HEADING} &nbsp;
        <div className="text-6xl animate-[wiggle_1s_ease-in-out_infinite]">
          👋
        </div>
      </div>
      <br />
      <div className="tracking-wide text-[#7C87AF]">{LOGIN_SUBHEADING}</div>
      <br />
      <Input
        onFocus={() => {
          setSelectedInput("name");
        }}
        placeholder={LOGIN_INPUT_NAME}
        label={LOGIN_INPUT_NAME}
        value={formData?.name}
        onChange={(event) => {
          const { value } = event?.target;
          onKeyboardInputChange("name", value);
        }}
      />
      <Input
        onFocus={() => {
          setSelectedInput("mobile");
        }}
        placeholder={LOGIN_INPUT_MOBILE}
        label={LOGIN_INPUT_MOBILE}
        value={formData?.mobile}
        onChange={(event) => {
          const { value } = event?.target;
          onKeyboardInputChange("mobile", value);
        }}
      />
      <br />
      <div>
        <IconButton
          buttonText={"Start"}
          isLoading={isLoading}
          onClick={handleClick}
        />
      </div>
      <Keyboard
        onChange={(value) => onKeyboardInputChange(selectedInput, value)}
        inputReference={selectedInput}
        keyboard={selectedInput === "mobile" ? "numbers" : "normal"}
      />
    </div>
  );
};
